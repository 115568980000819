import styled from 'styled-components';
import FadeInWhenVisible from './fade-in-when-visbile';
import theme from '../theme';

function FigureImage(props) {
    const { fileName, slug, altText, caption, background } = props;
    return (
        <FadeInWhenVisible>
            <Figure
                style={{
                    background: background ? background : 'transparent',
                }}
            >
                <img
                    src={require(`../assets/work/${slug}/${fileName}`)}
                    alt={altText ? altText : caption}
                />
                <figcaption>{caption}</figcaption>
            </Figure>
        </FadeInWhenVisible>
    );
}

const Figure = styled.figure`
    background: transparent;
    padding: ${theme.spacing.plus3};
    text-align: center;

    img {
        margin: auto;
    }
`;

export default FigureImage;
