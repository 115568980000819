import styled from 'styled-components';
import Section from '../section';
import Content from '../content';
import Text from '../text';
import theme from '../../theme';

function ProjectCategories(props) {
    return (
        <Categories>
            <Content>
                {props.fullWidth ? (
                    <ul>{props.children}</ul>
                ) : (
                    <Text>
                        <ul>{props.children}</ul>
                    </Text>
                )}
            </Content>
        </Categories>
    );
}
const Categories = styled(Section)`
    padding: ${theme.spacing.plus5} ${theme.spacing.plus3};
    border-bottom: 1px solid ${theme.colors.gray.dark};
    background: #000;

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
        grid-gap: ${theme.spacing.plus4};
    }

    @media (max-width: ${theme.breakpoints.tablet}) {
        padding: ${theme.spacing.plus4} ${theme.spacing.plus3};
    }
`;

export default ProjectCategories;
