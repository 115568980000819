import styled from 'styled-components';
import Text from './text';
import Section from './section';
import ProjectTeasers from './projects/project-teasers';
import Loading from './loading';
import theme from '../theme';

function Work(props) {
    const classes = props.is_hero ? 'section hero' : 'section';
    return (
        <Loading text="My portfolio">
            <MyWork className={classes}>
                <Section>
                    <Text>
                        <h1>My portfolio</h1>
                    </Text>
                </Section>
                <Text>
                    <p>
                        Over the course of my career, I have built a broad skill set that has
                        enabled me to bridge the gap between the world of design and development.
                        Not only do I enjoy the process of designing how interfaces are intended to
                        work, but I also take great pleasure in putting those designs into action.
                    </p>
                </Text>
            </MyWork>
            <ProjectTeasers noHeading />
        </Loading>
    );
}

const MyWork = styled(Section)`
    padding: ${theme.spacing.plus7} 0;

    ul {
        grid-template-columns: repeat(2, minmax(200px, 1fr));

        div a {
            min-height: 400px;
        }

        div h2 a {
            min-height: auto;
        }
    }

    h2 {
        font-size: ${theme.spacing.plus4};
        line-height: ${theme.spacing.plus4};
        padding-bottom: 0;
        margin: ${theme.spacing.plus2} 0 ${theme.spacing.plus2};
        color: ${theme.colors.gray.ultraLight};
    }

    @media (max-width: ${theme.breakpoints.mobileLandscape}) {
        ul {
            grid-template-columns: repeat(1, minmax(${theme.breakpoints.mobilePortrait}, 1fr));
        }
    }

    @media (max-width: ${theme.breakpoints.mobileLandscape}) {
        ul {
            grid-template-columns: repeat(1, minmax(${theme.breakpoints.mobilePortrait}, 1fr));
        }
    }
`;

export default Work;
