import styled from 'styled-components';
import classnames from 'classnames';
import Section from './section';
import Content from './content';
import theme from '../theme';
import utils from '../utils';

function Experience(props) {
    const { isHero, className } = props;
    const classes = classnames(`px-8 experience ${className}`, {
        'hero flex': isHero,
    });
    return (
        <SyledExperience className={classes}>
            <Content>
                <h1>Experience</h1>
                <ul className="columns">
                    <li>
                        <div>
                            <a href="https://thumbtack.com">Thumbtack</a>
                        </div>
                        {utils.title} / 2021-Present
                    </li>
                    <li>
                        <div>
                            <a href="https://unizin.org">Unizin</a>
                        </div>
                        {utils.title} / 2017-2021
                        <br />
                        Senior Web Developer / 2015-2017
                    </li>
                    <li>
                        <div>Courseload</div>
                        Front-end Developer / 2012-2015
                    </li>
                    <li>
                        <div>
                            <a href="https://gannett.com">Gannett</a>
                        </div>
                        Senior UX Designer / 2010-2012
                    </li>
                    <li>
                        <div>
                            <a href="https://indystar.com">The Indianapolis Star</a>
                        </div>
                        Senior Digital Designer / 2007-2010
                    </li>
                </ul>
            </Content>
        </SyledExperience>
    );
}

const SyledExperience = styled(Section)`
    max-width: ${theme.breakpoints.desktop};
    margin: ${theme.spacing.plus6} auto ${theme.spacing.plus6} auto;

    h1 {
        padding-bottom: ${theme.spacing.plus3};
    }

    .columns {
        padding: 0;
        margin: 0;
        list-style: none;
        display: grid;
        grid-template-columns: 50% 50%;
        grid-gap: ${theme.spacing.plus4};
        grid-auto-flow: columns;

        li {
            padding: 0;
            color: ${theme.colors.gray.light};

            div {
                font-weight: 900;
                font-size: ${theme.font.sizes.plus1};
                color: #fff;
            }

            a {
                text-decoration: none;

                :hover {
                    text-decoration: underline;
                }
            }
        }
    }

    @media (max-width: ${theme.breakpoints.tablet}) {
        h1 {
            font-size: ${theme.font.sizes.plus3};
            line-height: ${theme.font.sizes.plus3};
            padding-bottom: ${theme.spacing.plus3};
        }
    }

    @media (max-width: ${theme.breakpoints.mobilePortrait}) {
        .columns {
            grid-template-columns: auto;
        }
    }
`;

export default Experience;
