import React, { Component } from 'react';
import styled, { keyframes } from 'styled-components';
import classnames from 'classnames';
import Section from './section';
import theme from '../theme';

class Loading extends Component {
    constructor(props) {
        super(props);
        this.state = { show: true, dismiss: false };
    }

    // cheating here for now but this will eventually hook into an api call once the backend is ready
    componentDidMount() {
        document.body.classList.add('overflow-hidden');
        setTimeout(() => {
            this.setState({ dismiss: true });
        }, 1000);
    }

    // use animation event to trigger unmount
    onAnimationEnd = () => {
        this.setState({ show: false });
        document.body.classList.remove('overflow-hidden');
    };

    render() {
        const { dismiss, show } = this.state;
        const { primaryColor, text, kicker } = this.props;

        // add animation once element is ready
        const classes = classnames({
            remove: dismiss,
        });

        return (
            <>
                {show ? (
                    <Loader
                        className={classes}
                        onAnimationEnd={this.onAnimationEnd}
                        primaryColor={primaryColor}
                    >
                        <Section isHero isCentered>
                            <h1>
                                <Kicker>{kicker ? kicker : 'Loading'}</Kicker>
                                {text}
                            </h1>
                        </Section>
                    </Loader>
                ) : null}
                {this.props.children}
            </>
        );
    }
}

const transitionUp = keyframes`
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(-100%);
    }
`;

const Loader = styled.div`
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
    transform: translate(0, 0);
    box-shadow: 0 0 ${theme.spacing.plus4} hsla(0, 100%, 100%, 0.13);
    background-color: ${(props) => (props.primaryColor ? props.primaryColor : 'black')};

    &.remove {
        animation: ${transitionUp} 0.5s forwards;
    }

    section {
        max-width: ${theme.breakpoints.desktopHD};
        h1 {
            font-size: ${theme.font.sizes.plus6};
            line-height: ${theme.font.sizes.plus6};
            font-weight: 900;
            margin: 0;
            padding: 0;
        }
    }

    @media (max-width: ${theme.breakpoints.desktopHD}) {
        section.hero {
            padding-left: ${theme.spacing.plus3};
            padding-right: ${theme.spacing.plus3};

            h1 {
                font-size: ${theme.spacing.plus5};
                line-height: ${theme.spacing.plus5};
            }
        }
    }
`;

const Kicker = styled.div`
    text-transform: uppercase;
    font-size: ${theme.spacing.plus3};
    line-height: ${theme.spacing.plus3};
    font-weight: 600;
`;

export default Loading;
