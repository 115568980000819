import styled from 'styled-components';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import theme from '../../theme';
import utils from '../../utils';
import FadeInWhenVisible from '../fade-in-when-visbile';
const images = require.context('../../assets', true);

function ProjectTeaser(props) {
    const { slug } = props;
    const project = utils.projects.find((item) => {
        return item.slug === slug;
    });
    const { title, excerpt, discipline } = project;
    const classes = classnames({
        small: props.isSmall,
    });
    const projectHero = images(`./work/${slug}/project-hero.png`);

    return (
        <SyledProjectTeaser className={classes}>
            <FadeInWhenVisible>
                <ImageBlockMask>
                    <ImageBlock
                        to={`/work/${slug}`}
                        style={{
                            backgroundImage: `url(${projectHero})`,
                        }}
                        className="no-border"
                        onClick={props.handleClick}
                    >
                        <span className="aural">View {title} project</span>
                    </ImageBlock>
                </ImageBlockMask>
                <Base>
                    <h2>
                        <Link to={`/work/${slug}`} className="no-border">
                            {title}
                        </Link>
                    </h2>
                    <div>{excerpt}</div>
                    <Discipline>{discipline}</Discipline>
                </Base>
            </FadeInWhenVisible>
        </SyledProjectTeaser>
    );
}

const Discipline = styled.div`
    padding: ${theme.spacing.plus1} ${theme.spacing.plus3};
    font-size: ${theme.font.sizes.minus1};
    margin-top: ${theme.spacing.plus2};
    background: #222;
    border-radius: 500px;
    display: inline-block;
`;

const SyledProjectTeaser = styled.li`
    margin-bottom: ${theme.spacing.plus6};
    padding: ${theme.spacing.plus3} 0 ${theme.spacing.plus5} 0;
    width: calc(100% - (${theme.spacing.plus4} * 2));
    margin: 0 auto;

    @media (max-width: ${theme.breakpoints.tablet}) {
        margin-bottom: ${theme.spacing.plus2};
    }

    .small {
        min-height: 150px;
        max-height: 150px;
        max-width: 300px;
    }
`;

const ImageBlockMask = styled.div`
    background-color: ${theme.colors.gray.ultraDark};
    overflow: hidden;
    border-radius: ${theme.borderRadius.default};

    .small & {
        min-height: 150px;
        max-height: 150px;
        max-width: 250px;
    }
`;

const ImageBlock = styled(Link)`
    display: inline-block;
    width: 100%;
    height: 100%;
    min-height: 600px;
    transition: all 0.25s;
    box-shadow: 0 0 30px hsla(0, 100%, 0%, 0.13);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    &:hover {
        transform: scale(1.025);
    }

    .small & {
        min-height: 150px;
        max-height: 150px;
        max-width: 250px;
    }

    @media (min-width: ${theme.breakpoints.mobilePortrait}) {
        min-height: ${utils.imageMinHeight(theme.breakpoints.mobilePortrait)}px;
    }
    @media (min-width: ${theme.breakpoints.tablet}) {
        min-height: ${utils.imageMinHeight(theme.breakpoints.tablet)}px;
    }
    @media (min-width: ${theme.breakpoints.desktop}) {
        min-height: ${utils.imageMinHeight(theme.breakpoints.desktop)}px;
    }
    @media (min-width: ${theme.breakpoints.desktopHD}) {
        min-height: ${utils.imageMinHeight(theme.breakpoints.desktopHD)}px;
    }
`;

const Base = styled.div`
    h2 {
        font-size: ${theme.spacing.plus4};
        line-height: ${theme.spacing.plus4};
    }

    .small & {
        min-height: 150px;
        max-height: 150px;
        max-width: 250px;
    }

    @media (max-width: ${theme.breakpoints.tablet}) {
        h2 a {
            font-size: ${theme.font.sizes.plus2};
            line-height: ${theme.font.sizes.plus2};
        }

        .small & {
            h2 a {
                font-size: ${theme.font.sizes.plus1};
                line-height: ${theme.font.sizes.plus1};
            }
        }
    }
`;

export default ProjectTeaser;
