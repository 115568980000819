import styled from 'styled-components';
import ExternalLinkPromo from './external-link-promo';
import ProjectWrapper from './project-wrapper';
import Text from '../text';
import Section from '../section';
import Figure from '../figure';
import FadeInWhenVisible from '../fade-in-when-visbile';

function UnizinSummit2020(props) {
    const {
        project: { slug, primaryColor },
    } = props;

    return (
        <StyledProjectWrapper slug={slug} primaryColor={primaryColor}>
            <Text>
                <p>
                    While I'm the lead designer at Unizin and oversee the progression and
                    development of our digital products, I'm also responsible for all the design and
                    their resources, including our annual summit.
                </p>
            </Text>
            <Text>
                <h2 className="text-3xl pb-4">Problem to be solved</h2>
                <p>
                    Unizin hosts an annual summit that allows the organization and our member
                    institutions to network and establishes our yearly goals and expectations. This
                    event also will enable us to see new faces, learn about new products, talk about
                    existing projects, and a critical marketing opportunity for Unizin.
                </p>
                <p>
                    Each year, I produce a unique summit identity and visuals for every given year
                    the event takes place. It is an opportunity for us to be more playful by letting
                    our more traditional branding efforts take a back seat.
                </p>
                <p>
                    2020 yielded a pivotal change in how we talk about Unizin, how we operate, and
                    the release of our Unizin Data Platform (UDP) application. The maturation of
                    data, visualizations, and figures built a case for us to consider how we could
                    echo this visual change to our members.
                </p>
                {/* <p>(Show shape animations and visuals)</p> */}
            </Text>
            <Text>
                <h2 className="text-3xl pb-4">Summit Brand Identity</h2>
                <p>
                    2020 saw an insurgence of geometric patterns and geometric shapes. These
                    patterns are a throwback to 80s visuals but with a modern twist.
                </p>
                <p>
                    The 2020 summit intended to echo these trends seen in the marketplace by
                    building a basic framework and appear in all marketing materials used to promote
                    and deliver the summit experience.
                </p>
            </Text>
            <Text>
                <h2 className="text-3xl pb-4">Designing the assets</h2>
                <h3 className="text-2xl pb-4">The color palette</h3>
                <p>
                    I took our brand's primary color and paired it with two additional secondary
                    hues. I built an essential identity for the summit that we could use for
                    marketing and identification for this year's event specifically.
                </p>
                <FadeInWhenVisible>
                    <Figure
                        slug={slug}
                        fileName="color-scheme.png"
                        alt="Color palette for Unizin Summit 2020"
                        caption="Color palette for Unizin Summit 2020"
                    />
                </FadeInWhenVisible>
            </Text>
            <Text>
                <p>
                    Then I applied these colors and brands to shapes included in each asset. The
                    idea was to create a harmonious and orchestrated set of visuals tied back to our
                    brand color-wise and presented a unique take to the summit brand for 2020.
                </p>
            </Text>
            <Text>
                <h3 className="text-2xl pb-4">Badge & lanyard</h3>
                <p>
                    One of the critical components of a summit or conference is the badge and
                    lanyard. Badges are the asset that gets the most attention due to its wide use.
                    No matter the length of your conference, whether it be a few hours or multiple
                    days, this is your means of identification and validation of entry.
                </p>
                <p>
                    Knowing the intention of this element, I focussed on the practical needs the
                    badge would serve. Not only could this be your key to entry, but couldn't it
                    also act as a reference to your business card, access to your virtual schedule,
                    and be a physical schedule as well.
                </p>
            </Text>

            <Section>
                <Figure
                    slug={slug}
                    fileName="extras/badges.png"
                    alt="Summit 2020 badge"
                    caption="Summit 2020 badge"
                />
                <Figure
                    slug={slug}
                    fileName="extras/lanyard.png"
                    alt="Summit 2020 lanyard"
                    caption="Summit 2020 lanyard"
                />
            </Section>

            <Text>
                <h3 className="text-2xl pb-4">Additional collateral</h3>
                <p>
                    The additional collateral needed for the summit included Unizin branded apparel
                    and free-standing banners. These banners would be retractable and sit on the
                    stage to frame the presenters with labeling reflecting the event sponsors.
                </p>
                <p>
                    Apparel design was centered around the need for Unizin staff and event hosts to
                    be easily recognizable by summit attendees. Staff members had the option to
                    choose either a T-shirt or a colored black long-sleeve button-up.
                </p>
                <Figure
                    slug={slug}
                    fileName="extras/apparel.png"
                    alt="Apparel options for staff and hosts"
                    caption="Apparel options for staff and hosts"
                />
            </Text>

            <Text>
                <p>
                    While I was very excited to see all of these elements come to fruition in a
                    physical space, 2020 didn't allow us to host a physical event. We did, however,
                    recycle these materials for our online virtual summit that happened in 2021,
                    which was still a win, but it would have been nice to see this play out and its
                    entirety.
                </p>
            </Text>
            <Text>
                <ExternalLinkPromo
                    primaryColor={primaryColor}
                    url="https://www.behance.net/gallery/91930741/Unizin-Summit-2020"
                    source="Behance"
                    kicker="View on Behance"
                    text="To see more images and a collective storyline, check out this project on my Behance profile."
                />
            </Text>
        </StyledProjectWrapper>
    );
}

const StyledProjectWrapper = styled(ProjectWrapper)`
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: #a88ed7;
    }
`;

export default UnizinSummit2020;
