import styled from 'styled-components';
import Navigation from './navigation';
import Section from './section';
import images from '../assets/svgs';
import theme from '../theme';
import utils from '../utils';

function Footer() {
    return (
        <StyledFooter>
            <Section className="px-8">
                <div className="columns centered">
                    <div>
                        <h1>Erik Goens</h1>
                        <div>{utils.title}</div>
                        <p>Designer of human-centered software solutions</p>
                        <div className="separator"></div>
                        <p>
                            You'll find most of my activity on instagram. Pick your poison and feel
                            free to drop me a line to connect.
                        </p>
                    </div>
                    <div>
                        <Navigation hideProjects hideProjectLinks />
                        <ul className="social-links">
                            <li>
                                <a href="https://www.instagram.com/egoens/">Instagram</a>
                            </li>
                            <li>
                                <a href="https://github.com/egoens">Github</a>
                            </li>
                            <li>
                                <a href="https://www.linkedin.com/in/erikgoens/">LinkedIn</a>
                            </li>
                            <li>
                                <a href="https://www.behance.net/ErikGoens">Behance</a>
                            </li>
                            <li>
                                <a href="https://twitter.com/egoens">Twitter</a>
                            </li>
                            <li>
                                <a href="https://www.strava.com/athletes/803326">Strava</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </Section>
        </StyledFooter>
    );
}

const StyledFooter = styled.footer`
    padding-top: ${theme.spacing.plus6};
    padding-bottom: ${theme.spacing.plus6};
    background-color: #000;
    border-top: 1px solid ${theme.colors.gray.dark};
    /* prettier-ignore */
    background-image: url("${images.topoImage}");

    h1,
    ul,
    li,
    p {
        margin: 0;
    }

    h1 {
        font-size: ${theme.font.sizes.plus5};
    }

    .columns {
        flex-direction: row;
        max-width: ${theme.breakpoints.desktopHD};
        margin: auto;

        > div {
            flex: 50%;
        }

        > div:nth-child(1) {
            div {
                font-size: ${theme.spacing.plus3};
                font-weight: 700;
                color: ${theme.colors.gray.light};
            }
        }

        > div:nth-child(2) {
            align-items: flex-end;
        }
    }

    div.nav {
        padding: 0;
        height: auto;

        .nav-links {
            margin-right: 0;
            padding-bottom: ${theme.spacing.plus3};
        }
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0 0 ${theme.spacing.plus2};
        text-align: right;

        li {
            padding-bottom: ${theme.spacing.plus1};
            text-align: right;
        }
    }

    .separator {
        width: ${theme.spacing.plus5};
        height: 2px;
        background: ${theme.colors.gray.light};
        margin: ${theme.spacing.plus5} 0;

        + p {
            color: ${theme.colors.gray.light};
        }
    }

    @media (max-width: ${theme.breakpoints.tablet}) {
        padding-top: ${theme.spacing.plus2};
        padding-bottom: ${theme.spacing.plus2};

        h1 {
            font-size: ${theme.font.sizes.plus4};
            line-height: ${theme.font.sizes.plus4};
            padding-bottom: ${theme.spacing.plus2};
        }

        .separator {
            margin: ${theme.spacing.plus4} 0;
        }

        .columns {
            > div {
                flex: 100%;

                &:nth-child(2) {
                    padding-top: ${theme.spacing.plus3};
                    align-items: flex-start;
                }
            }
        }

        ul li {
            text-align: left;
        }
    }
`;

export default Footer;
