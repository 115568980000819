import Text from '../text';
import Section from '../section';
import Tabs from '../tabs';
import Figure from '../figure';
import FadeInWhenVisible from '../fade-in-when-visbile';
import ProjectWrapper from './project-wrapper';

import WireframeOne from '../../assets/work/order-tool/wireframes/1.png'
import WireframeTwo from '../../assets/work/order-tool/wireframes/2.png'
import WireframeThree from '../../assets/work/order-tool/wireframes/3.png'
import WireframeFour from '../../assets/work/order-tool/wireframes/4.png'

function OrderTool(props) {
    const tabs = [
        { title: 'Sections', caption: 'Allow the user to select a course and section combination' },
        { title: 'Orders', caption: 'Select from previous orders placed' },
        { title: 'Confirmation', caption: 'Confirmation of order placement' },
    ];
    const {
        project: { slug, primaryColor },
    } = props;

    return (
        <ProjectWrapper slug={slug}>
            <Text>
                <p>
                    Think about a time when you purchased a book online and what that experience was
                    like for you. It's likely that you chose a trusted and known resource or series
                    of resources to search for that book, compared price points, added your items to
                    a cart, checked out, and then paid—a very linear and sequential online shopping
                    experience we're accustomed to seeing.
                </p>
                <p>
                    Ordering digital course materials in an institutional setting is a unique
                    shopping experience than the traditional retail sense. Not only do users need to
                    determine which teaching materials to order, but those materials also need to
                    map onto a particular course and section combination for any given term within
                    an academic year.
                </p>
                <p>
                    At its root, the general experience is the same when evaluating which materials
                    to order or purchase. Still, the choice in which those materials get delivered
                    introduces new challenges to building a shopping experience.
                </p>
            </Text>
            <Text>
                <h2 className="text-3xl pb-4">Persona-based shopping experiences</h2>
                <p>
                    Not all shoppers are the same. That's especially true in our case. The volume of
                    orders a user will likely order can vary drastically depending on their
                    institution's role. Ordering expectations becomes more challenging when users
                    carry multiple roles.
                </p>
                <p>
                    Our initial approach was more along the lines of a Google-like profiling
                    experience where a user could toggle between each role to accomplish their goals
                    that were unique to that persona. So, for example, if you were both an
                    instructor and a student, you would be delivered a different set of experiences
                    based on your current selected role.
                </p>
                <Section>
                    <Figure
                        slug={slug}
                        fileName="personas.png"
                        alt="Order Tool Personas"
                        caption="Order Tool Personas"
                    />
                </Section>
                <p>
                    While this approach is valuable for targeting each persona's specific needs, our
                    users had to relearn too many patterns when swapping their roles. It's critical
                    to consider the depth of knowledge each user may need with changing
                    roles/accounts.
                </p>
                <p>
                    As a result, we shifted to delivering a hierarchical model where the highest
                    elevated user role determines the experience. The structure sets out to provide
                    a more habit-forming and power-user-based platform. This approach enables
                    patterns users are familiar with and reduces the cognitive overhead of
                    reestablishing themselves with a new role in the same app.
                </p>
            </Text>
            <Text>
                <h2 className="text-3xl pb-4">Reordering materials</h2>
                <p>
                    After our tool gained traction at our institutions, we started conducting
                    interviews to understand better what features would be the most useful to focus
                    on for implementation. There was an overwhelming consensus that reordering the
                    same digital course materials every term was the most desired feature. We then
                    put together some preliminary wireframes to get a good idea of how we thought
                    this tool might work internally.
                </p>
            </Text>
            <FadeInWhenVisible>
                <Section fullWidth>
                    <ul className="flex flex-row overflow-x-scroll md:justify-evenly">
                        <li className="px-4">
                            <figure>
                                <img
                                    src={WireframeOne}
                                    alt=""
                                />
                                <figcaption>
                                    Decision point on which course the order is to be placed
                                </figcaption>
                            </figure>
                        </li>
                        <li className="px-4">
                            <figure>
                                <img
                                    src={WireframeTwo}
                                    alt=""
                                />
                                <figcaption>Section selection</figcaption>
                            </figure>
                        </li>
                        <li className="px-4">
                            <figure>
                                <img
                                    src={WireframeThree}
                                    alt=""
                                />
                                <figcaption>Transition into placement of order</figcaption>
                            </figure>
                        </li>
                        <li className="px-4">
                            <figure>
                                <img
                                    src={WireframeFour}
                                    alt=""
                                />
                                <figcaption>Order placement and editing options</figcaption>
                            </figure>
                        </li>
                    </ul>
                </Section>
            </FadeInWhenVisible>
            <Text>
                <p>
                    Using those wireframes together allowed us to see how this feature intended to
                    work. We then generated a usability study to present to our Program
                    Administrators. The study involved a series of questions about the usefulness
                    and utility of the tool. It also included a prototype to allow these users to
                    understand better how the tool's intention.
                </p>

                <p>
                    I used Maze design to produce and generate the results of the study. The study
                    was not a series of one on one interviews but was a remote-based usability test
                    that allowed each user to participate on their own time. These users do have a
                    heavy investment in our product and tools. I was pleasantly surprised with the
                    70+% participation.
                </p>

                <p>
                    The insights helped us understand which pathways we had correct and where we
                    needed to correct our path. It also allowed us to have some follow-up
                    conversations with these users and better understand our intentions for the
                    reordering feature.
                </p>
                <p>
                    I found a common misconception or misunderstanding about what it means to
                    reorder versus what it meant to duplicate an order. These two concepts are
                    fundamentally different in their intention and user needs. I found a need for
                    both, but more importantly, the desire for reordering is primary and potentially
                    duplicating secondary. Lastly, this allowed us to understand that there may also
                    be a need for automating the process for reordering digital course materials
                    term-over-term.
                </p>
            </Text>
            <FadeInWhenVisible>
                <div className="px-8">
                    <Tabs tabs={tabs} project="order-tool" primaryColor={primaryColor} />
                </div>
            </FadeInWhenVisible>
        </ProjectWrapper>
    );
}

export default OrderTool;
