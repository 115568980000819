import styled, { keyframes } from 'styled-components';
import theme from '../theme';
import images from '../assets/svgs';
import utils from '../utils';
import { motion, useTransform, useScroll } from 'framer-motion';

function Intro(props) {
    const { scrollY } = useScroll();
    const scrollYHero = useTransform(scrollY, [0, 700], ['0%', '70%']);
    const scrollYHeroOpacity = useTransform(scrollY, [0, 1000], [1, 0.6]);
    const scrollMouseOpacity = useTransform(scrollY, [0, 50], [1, 0]);

    return (
        <>
            <SyledIntro
                className="section hero"
                initial={{ opacity: 1 }}
                style={{ opacity: scrollYHeroOpacity }}
            >
                <motion.div initial={{ y: 0 }} style={{ y: scrollYHero }}>
                    <h1>Erik Goens</h1>
                    <span>{utils.title}</span>
                    <p>Designer of human-centered software solutions</p>
                </motion.div>
            </SyledIntro>
            <StyledIcon style={{ opacity: scrollMouseOpacity }} />
        </>
    );
}

const SyledIntro = styled(motion.section)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 ${theme.spacing.plus5};
    position: relative;
    background-color: #000000;
    border-bottom: 1px solid ${theme.colors.gray.ultraDark};
    /* prettier-ignore */
    background-image: url("${images.topoImage('%23111111')}");
    left: 0;

    &.section {
        padding-top: 0;

        h1 {
            font-size: ${theme.spacing.plus7};
            line-height: ${theme.spacing.plus7};
            font-weight: 900;
            margin: 0;
            padding: 0;
        }
    }

    > div {
        transform-origin: left center;
    }

    span {
        display: block;
        color: ${theme.colors.gray.light};
        font-weight: 700;
        font-size: ${theme.spacing.plus5};
        line-height: 100%;
    }
    p {
        margin: 0;
        padding: 0;
    }

    @media (max-width: ${theme.breakpoints.tablet}) {
        padding: 0 ${theme.spacing.plus3};

        &.section {
            h1 {
                font-size: ${theme.spacing.plus5};
                line-height: ${theme.spacing.plus5};
            }
        }

        span {
            font-size: ${theme.spacing.plus3};
            line-height: ${theme.spacing.plus3};
            padding-bottom: ${theme.spacing.plus3};
        }
    }
`;

const mousescroll = keyframes`
    0% {
        transform: translate(-50%, -65px);
    }

    /* Finish changes by here */
    60% {
        transform: translate(-50%, -65px);
    }

    70% {
        transform: translate(-50%, -90px);
    }

    90% {
        transform: translate(-50%, -90px);
    }

    /* Between 20% and 100%, nothing changes */
    100% {
        transform: translate(-50%, -65px);
    }
`;

const StyledIcon = styled(motion.div)`
    position: relative;

    &:before {
        content: '';
        position: absolute;
        width: 30px;
        height: 50px;
        left: 50%;
        transform: translate(-50%, -100px);
        border: 1px solid ${theme.colors.gray.light};
        border-radius: 15px;
    }
    &:after {
        content: '';
        position: absolute;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: ${theme.colors.gray.light};
        left: 50%;
        transform: translate(-50%, -65px);
        animation: ${mousescroll} 10s infinite;
    }
`;

export default Intro;
