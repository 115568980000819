import * as React from 'react';
import styled from 'styled-components';
import { motion, useTransform, useViewportScroll, AnimatePresence } from 'framer-motion';
import classnames from 'classnames';
import Section from '../section';
import ProjectCategories from '../projects/project-categories';
import ProjectCategory from '../projects/project-category';
import Text from '../text';
import Loading from '../loading';
import theme from '../../theme';

import { useInView } from 'react-intersection-observer';

const HeroWrapper = React.forwardRef((props, ref) => {
    const { slug } = props;
    const { scrollY } = useViewportScroll();
    const scrollYHero = useTransform(scrollY, [0, 2000], ['100vh', '0vh']);
    const heroImage = require(`../../assets/work/${slug}/project-hero.png`);

    return (
        <Hero
            initial={{ height: '100vh' }}
            style={{ height: scrollYHero, backgroundImage: `url("${heroImage}")` }}
            ref={ref}
        />
    );
});

// how to implement intersection observer
// https://codesandbox.io/s/useinview-ud2vo?fontsize=14&hidenavigation=1&theme=dark&file=/src/elements/Status.tsx
const ScrollWrapper = ({ children, inView, ...props }) => {
    return (
        <>
            <Promo inView={inView} testing="string" project={props.project} />
            {children}
        </>
    );
};

function Promo({ inView, ...props }) {
    const {
        project: { title, slug },
    } = props;
    const promoTeaser = require(`../../assets/work/${slug}/promo-teaser.png`);
    return (
        <AnimatePresence>
            {!inView ? (
                <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                    <StyledPromo className="flex">
                        <div
                            className="flex-none"
                            style={{ backgroundImage: `url(${promoTeaser})` }}
                        ></div>
                        <div className="flex-1">
                            <Kicker>Currently viewing</Kicker>
                            <Title>{title}</Title>
                        </div>
                    </StyledPromo>
                </motion.div>
            ) : null}
        </AnimatePresence>
    );
}

function Project(props) {
    const {
        children,
        className,
        project: { title, excerpt, primaryColor, categories, discipline, slug },
        project,
    } = props;
    const classes = classnames({
        [`${className}`]: className,
    });

    const [ref, inView] = useInView({
        threshold: 0,
    });

    return (
        <Loading primaryColor={primaryColor} text={title}>
            <ScrollWrapper inView={inView} project={project}>
                <SyledProject className={classes}>
                    <div ref={ref}>
                        <HeroWrapper slug={slug} />
                        <ProjectCategories fullWidth>
                            {Object.entries(categories).map(([k, v]) => {
                                return <ProjectCategory key={k} kicker={k} text={v} />;
                            })}
                        </ProjectCategories>
                    </div>
                    <Body primaryColor={primaryColor}>
                        <Section>
                            <Text>
                                <h1>{title}</h1>
                                <Excerpt>{excerpt}</Excerpt>
                                <Discipline>{discipline}</Discipline>
                            </Text>
                            {children}
                        </Section>
                    </Body>
                </SyledProject>
            </ScrollWrapper>
        </Loading>
    );
}

const StyledPromo = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    background: #000;
    border-bottom: 1px solid #333;
    width: 100vw;
    box-shadow: 0 20px 20px rgba(0, 0, 0, 0.2);

    .flex-none {
        width: 64px;
        height: 48px;
        background-position: center;
        background-size: 150%;
    }

    .flex-1 {
        padding: ${theme.spacing.plus1} ${theme.spacing.plus3} ${theme.spacing.plus1}
            ${theme.spacing.plus2};
    }

    @media (max-width: ${theme.breakpoints.tablet}) {
        top: auto;
        bottom: 0;

        .flex-none {
            width: 64px;
            height: 64px;
        }

        .flex-1 {
            padding-bottom: ${theme.spacing.plus3};
        }
    }
`;

const Kicker = styled.div`
    font-size: ${theme.font.sizes.minus4};
    line-height: ${theme.font.sizes.minus4};
    color: ${theme.colors.gray.light};
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 1px;
`;

const Title = styled.div`
    font-weight: bold;
`;

const Excerpt = styled.div`
    font-size: ${theme.font.sizes.plus1};
`;

const Discipline = styled.div`
    padding: ${theme.spacing.plus1} ${theme.spacing.plus3};
    font-size: ${theme.font.sizes.minus1};
    margin-top: ${theme.spacing.plus2};
    background: #222;
    border-radius: 500px;
    display: inline-block;
`;

const SyledProject = styled.section`
    .teasers {
        padding-top: ${theme.spacing.plus6};
        border-top: 1px solid ${theme.colors.gray.dark};
    }
`;

const Body = styled(Section)`
    padding: 0;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-weight: 900;
        color: ${(props) => (props.primaryColor ? props.primaryColor : 'white')};
    }

    .text {
        padding-bottom: ${theme.spacing.plus5};
    }
`;

const Hero = styled(motion.div)`
    height: 100vh;
    width: 100vw;
    padding: 0;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    .content {
        padding: 2rem;
    }
`;

export default Project;
