import { Link } from 'react-router-dom';
import styled from 'styled-components';
import ProjectWrapper from './project-wrapper';
import Section from '../section';
import Tabs from '../tabs';
import Text from '../text';
import FadeInWhenVisible from '../fade-in-when-visbile';
import Figure from '../figure';

function Reordering(props) {
    const {
        project: { slug, primaryColor },
    } = props;

    const tabs = [
        { title: 'Sections', caption: 'Allow the user to select a course and section combination' },
        { title: 'Orders', caption: 'Select from previous orders placed' },
        { title: 'Confirmation', caption: 'Confirmation of order placement' },
    ];

    return (
        <ProjectWrapper slug={slug}>
            <Text>
                <h2 className="text-3xl pb-4">Problem to be solved</h2>
                <p>
                    On average, our member institutions place 483 orders each term. The vast
                    majority of those orders historically have been placed by Program Administrators
                    (PA). PAs are responsible for overseeing their digital content programs at their
                    institutions. These responsibilities can and do include placing orders for
                    faculty and staff, but this process should be considered more obligatory than
                    mandatory.
                </p>
                <p>
                    While ordering through the Order Tool application has simplified an already
                    convoluted, error prone, and archaic process. I needed to find a faster way to
                    nudge faculty and staff to produce their own orders.
                </p>
            </Text>
            <Text>
                <h2 className="text-3xl pb-4">Overall goal</h2>
                <p>
                    The overall intention of this project was to lift the weight off of the PAs'
                    shoulders role, placing orders to enable them to reallocate their time to focus
                    on the growth of their institution's digital content program. Relinquishing the
                    pressure for PA ordering would push the burden onto the instructors, who would
                    be using the digital course materials in their pedagogy, and acclimate them into
                    an ordering experience they might not have otherwise been privy to using.
                </p>
                <ol>
                    <li>Reduce the number of orders placed by PAs by 50%</li>
                    <li>Increase the number of orders placed by Instructors by 200%</li>
                    <li>Reduce the amount of time it takes a user to place an order</li>
                    <li>And of course, customer satisfaction</li>
                </ol>
            </Text>
            <FadeInWhenVisible>
                <Section>
                    <Figure slug={slug} fileName="reordering.png" alt="Reordering" />
                </Section>
            </FadeInWhenVisible>
            <Text>
                <h2 className="text-3xl pb-4">Project planning</h2>
                <p>
                    Before getting started with the project, I wanted to get a broad understanding
                    of how I would produce a success reordering pattern. I did this by building a
                    out platform for research, information architecture, feedback, and execution.
                </p>
                <ol>
                    <li>Interview our users and understand the primary user personas</li>
                    <li>Understand how users order today and where we can make improvements</li>
                    <li>Examine successful market examples</li>
                    <li>Build wireframes and flowcharts to understand the intended path</li>
                    <li>Test concepts with prototypes and define user stories</li>
                    <li>Build high-fidelity assets, prototype, and design requirements</li>
                    <li>Measure success</li>
                </ol>
            </Text>

            <Text>
                <h2 className="text-3xl pb-4">UX Research</h2>
                {/* <h3 className="text-2xl pb-4">Persona-based shopping experiences</h3> */}
                <p>
                    Not all shoppers are the same. That's especially true in our case. The volume of
                    orders a user will likely order can vary drastically depending on their
                    institution's role. Ordering expectations becomes more challenging when users
                    carry multiple roles.
                </p>
                <p>
                    However, the intention of this exercise was thinking about how I could focus on
                    improving the ordering experience through the lens of our PA and Instructor
                    personas.{' '}
                </p>

                <h3 id="user-interviews" className="text-2xl pb-4">
                    Interviewing our users
                </h3>
                <p>
                    Knowing that our member institutions are generally protective of the noise
                    introduced to instructors at their institution, I started our research efforts
                    with <b>qualitative interviews</b> with their digital content PA. These users
                    act as the gatekeeper and facilitator of these programs, and are keenly aware of
                    the conversations and support needed for our Instructor <b>personas</b>.
                </p>
                <p>
                    This <b>generative study</b> led to a series of insights into how I can support
                    a more simplified approach to placing orders. I then discovered that it's very
                    common that the same digital course materials are used for the same course and
                    subject each year.
                </p>
                <FadeInWhenVisible>
                    <Section>
                        <Figure
                            slug={slug}
                            fileName="personas.png"
                            alt="Order Tool Personas"
                            caption="Reordering feature target personas"
                        />
                    </Section>
                </FadeInWhenVisible>
                <p>
                    For example, if an instructor teaching Bio 101 in Fall 2020 and ordered Intro to
                    Biology for that term, then the probability for the same course material to be
                    ordered for Bio 101 in Fall 2021 was extremely high. Not only was this evident
                    as a key takeaway from our conversations, but the data also supported this
                    pattern.
                </p>
                <p>
                    Conceptually I were able to understand there was a greater need for building
                    ways to expedite ordering for users who have previously placed an order, and
                    those who are not avid users of our platform. I needed a way to support the
                    reordering of course materials for any instructor persona at the course and
                    subject level. This same process could also be used as a selling point for new
                    users.
                </p>
                <h3 id="task-analysis" className="text-2xl pb-4">
                    Task Analysis
                </h3>
                <p>
                    Focussing on streamlining the ordering experience was my primary objective for
                    the Reordering work. How can I get the user to place an order in the shortest
                    amount of steps possible?
                </p>
                <p>
                    The first phase of understanding this problem was conducting a task evaluation
                    on the current ordering platform. The study's intention was to get a sense of
                    how users accomplish the goal of placing an order today and understand what
                    portions of that process can be trimmed down to expedite the process.
                </p>
                <p>
                    After an initial evaluation, I noticed at a minimum, the user has to complete 10
                    required tasks to create an order (27 tasks depending on the depth of
                    filtering). These tasks are spread across the span of 6 steps.
                </p>
            </Text>
            <FadeInWhenVisible>
                <div className="flex">
                    <div className="flex-1">
                        <Figure
                            slug={slug}
                            fileName="task-analysis.png"
                            alt="Ordering task analysis"
                            caption="Pre-reordering: 10 required steps to place an order."
                        />
                    </div>
                    <div className="flex-1">
                        <Figure
                            slug={slug}
                            fileName="task-analysis-reordering.png"
                            alt="Ordering task analysis"
                            caption="Reordering potential: 6 required steps to place an order."
                        />
                    </div>
                </div>
            </FadeInWhenVisible>
            <Text>
                <p>
                    By introducing a method for users to reorder, I made a 40% task reduction. Users
                    will complete an order process in 6 required tasks (10 tasks depending on the
                    depth of filtering) and done within four steps.
                </p>
                <p>
                    This reduction in time is not only just a huge saving for individual orders, but
                    the multiplier for users creating 1+ orders will result in drastic time savings.
                </p>
                <p>
                    The substantial savings of time comes with introducing our email notification,
                    enabling users to start ordering from their inbox. This route will result in an
                    80% task reduction. This means it only takes two clicks to complete!
                </p>
            </Text>
            <Text>
                <h3 id="competitive-analysis" className="text-2xl pb-4">
                    Examine successful market examples
                </h3>
                <p>
                    The concept of reordering is not new. Finding the proper patterns that were
                    already a part of the general marketplace and understanding how others
                    approached the same problem was how I intended to evaluate the competition.
                </p>
                <p>
                    I decided to conduct a <b>"competitive analysis"</b> targeting indirect
                    competitors to get a pulse on reordering behaviors. This is air quoted because
                    none of our direct competitors offer the concept of reordering today and it's
                    wasn't possible to provide a 1:1 comparison against our competitors.
                </p>
                <p>
                    I selected Amazon, Popeyes, Shake Shack, and Harry's Shaving to evaluate. These
                    examples provide a wide variety of marketplace options to examine and have been
                    well praised by colleagues and personally on what constitutes a good experience
                    for reordering materials.
                </p>
            </Text>
            <Section>
                <Figure
                    slug={slug}
                    fileName="competitive-analysis.png"
                    alt="Competitive Analysis"
                    caption="Evaluated reordering behaviors on Amazon, Popeyes, Shake Shack & Harry’s
                    Shaving"
                />
            </Section>
            <Text>
                <p>
                    The most interesting pattern uncovered from this analysis was how Harry’s
                    shaving handled their scheduled delivery service. Conceptually this mapped
                    fairly closely to how I handle ordering through our portal.
                </p>
            </Text>
            <Text>
                <h3 id="flowcharts" className="text-2xl pb-4">
                    Wireframes and flowcharts
                </h3>
                <p>
                    Before I started applying the outcomes of the <b>qualitative interviews</b> and{' '}
                    <b>task analysis</b> work to the <b>prototyping phase</b>, I wanted to get a
                    clearer sense of how a user would navigate the process of reordering.
                </p>
                <p>
                    To do so, I produced a series of <b>flowcharts</b> of how our personas would
                    navigate through the process of reordering. The intention was to get a better
                    sense of the complexities and logic of how a user accomplishes their goal.
                </p>
            </Text>
            <Section>
                <Figure
                    slug={slug}
                    fileName="reordering-flowchart.png"
                    alt="Reordering flowchart"
                    caption="Flowchart example of how a user will place an order"
                />
            </Section>
            <Text>
                <h3 className="text-2xl pb-4">Test concepts with prototypes</h3>
                <p>
                    To get a better sense of how our users interact with our reorder elements, I
                    generated a series of <b>prototypes</b>, each introducing different entry points
                    for reordering. The intention was to understand the most likely starting point
                    for reordering and how intuitive each pathway would be.
                </p>
                <p>
                    These prototypes were used to evaluate a task-based usability test via{' '}
                    <a href="https://maze.co">Maze.</a> The intention was to understand the entry
                    points our users take to place an order and if the pattern of duplicating an
                    existing order was practical and understood.
                </p>
                <p>
                    I started with a non-mobile or desktop approach first since our quantitative
                    metrics clearly indicated that this channel still reigned supreme by the vast
                    majority of users (~90%).
                </p>
            </Text>
            <Text>
                <h3 className="text-2xl pb-4">Defining job stories</h3>
                <p>
                    I had conducted enough research to confidently move forward with producing a set
                    of designs that ultimately shape the designs for our ordering platform. We
                    understood enough of the instructor's journey to define our job stories.
                </p>
                <p>
                    Working closely with the product manager, I formed the expectations of
                    application performance through the lens of <b>personas</b> involved with
                    reordering. Ultimately this led to the problems sets that would inform our
                    design work.
                </p>
            </Text>
            <Text>
                <h2 id="design" className="text-3xl pb-4">
                    Design
                </h2>
                <h3 className="text-2xl pb-4">High-fidelity designs</h3>
                <p>
                    Now moving into the stage where the design elements start to form a richer or
                    more high-fidelity approach. I had a clearer sense of what needed to be done,
                    but now it was about tying the experience together with real design elements in
                    Figma.
                </p>
                <p>
                    Leveraging our <Link to="unizin-design-system">design system</Link> to act as
                    the foundation. It's important that I adopt reusable elements and patterns from
                    our library, and adhere to the general rulesets that govern usage of our
                    components, language, styles, etc. This included elements from our desktop
                    experience and our email design, as well.
                </p>
            </Text>
            <FadeInWhenVisible>
                <div className="px-8">
                    <Tabs tabs={tabs} project="order-tool" primaryColor={primaryColor} />
                </div>
            </FadeInWhenVisible>
            <Text>
                <h3 className="text-2xl pb-4">Fully interactive prototype</h3>
                <p>
                    I connected our designs to a series of interactions to prototype the intended
                    experience with high-resolution assets in <b>Figma</b>. This gave us the
                    opportunity to fully unpack the breadth and depth of expectations, and define
                    the nuances for engineering.
                </p>
                <p>
                    These <b>prototypes</b> act as the communication piece for demoing later in the
                    process. This also serves as a reference point for anyone to acclimate
                    themselves to the process or act as a reminder on intention if need be. This
                    provided an opportunity to work with realtime data coming from our users, and
                    connect with a real-life experience without needing to introduce engineering
                    overhead.
                </p>
                <p>
                    Populating data into our designs comes through in one of two ways. It's either
                    an exported set of structured .csv files pulled through syncing data from{' '}
                    <a href="https://www.figma.com/community/plugin/735770583268406934/Google-Sheets-Sync">
                        Google Sheets Sync
                    </a>{' '}
                    or through using{' '}
                    <a href="https://www.figma.com/community/plugin/789839703871161985/JSON-to-Figma">
                        JSON to Figma
                    </a>{' '}
                    to populate our designs.
                </p>
                <p>
                    Lastly, these <b>prototypes</b> help provide the visual narrative and manage the
                    expectations of our cross-functional product and engineering teams. Without
                    these resources, conversations become too fragile and intentions tend to
                    deteriorate. These assets help align our UX goals with our business and product
                    goals.
                </p>
            </Text>
            <Text>
                <h3 id="implementation" className="text-2xl pb-4">
                    Moving to implementation
                </h3>
                <p>
                    I worked closely with the front-end engineer to dial in our approach from a
                    design-to-UI perspective. This meant getting a local environment setup to run
                    the feature locally, test the interactions, and validate the expectations from a
                    UI and UX perspective.
                </p>
            </Text>
            <Section>
                <Figure
                    slug={slug}
                    fileName="requirements-full.png"
                    alt="Product design requirements"
                    caption="UI & UX requirements defined for the Reordering portion of the Unizin
                    Order Tool application."
                />
            </Section>
            <Text>
                <h3 id="evaluating-experience" className="text-2xl pb-4">
                    Evaluating the experience
                </h3>
                <p>
                    To continue keeping an eye on the trends and behaviors of this experience, we
                    leveraged <a href="https://intercom.com/">Intercom</a>. The more common use case
                    for this service is to directly connect with customers in a customer experience
                    capacity. However, it can also be used as a way to build{' '}
                    <b>user segmentations</b> for your user base and evaluate their trends.
                </p>
                <p>
                    To help guide the evaluation of our users, I worked with the product manager to
                    form the definitions for our <b>user segmentations</b>. I wanted to get a
                    clearer understanding of which users are highly active, identify who are not,
                    who are good leads, and how I could structure our intentions around these
                    collections of users.
                </p>
                <p>
                    Once those rules were defined, these gave us a framework to apply to Intercom
                    and a basic set of api adjustments needed to be made to enable targeting the
                    right segmentation of users.
                </p>
            </Text>
            <Text>
                <h3 id="measuring-success" className="text-2xl pb-4">
                    Measuring success
                </h3>
                <p>
                    The expectation is that I'll see a significant uptick in the number of orders
                    placed by instructors, and inversely a vast decline in orders produced by PAs.
                    While reordering does require the existence of a previously placed order, the
                    prospect of ease of use is expected to be enough of an incentive to move
                    instructors to produce their own orders in the future.
                </p>
                <p>
                    Also, Instructors can leverage the existence of previously placed orders (tied
                    to their teaching assignment for Course + Subject Number) to duplicate an order
                    placed by a PA in the past. I expect to see drastic changes that invert the
                    percentages of placements at each of our member institutions.
                </p>
                <YoyPromo className="pt-8 pb-16 text-center">
                    <div className="xl:text-9xl text-6xl font-black leading-none">1517%</div>
                    <div className="uppercase sm:text-sm text-xs tracking-wider">
                        YOY increase in Instructor ordering
                    </div>
                </YoyPromo>
                <p>
                    The reordering feature was released to the Order Tool application for Fall 2021
                    ordering. So far s of August 25, 2021, <b>364</b> Instructors have placed an
                    order for this Fall term. That's up from the total of <b>24</b> Instructor users
                    who placed in order in the Fall 2020 term. That's a{' '}
                    <b>1517% year-over-year increase </b> for Instructor order placement. While that
                    doesn't account for the overall increase in orders placed, I'm excited to see
                    how this feature will reduce the volume of orders placed by the PA users.
                </p>
            </Text>
            <Text>
                <h2 className="text-3xl pb-4">Lessons learned</h2>
                <p>
                    While it seemed very clear to our PAs what they needed to reorder previously
                    placed orders, this was an accentuating piece to that process that uncovered
                    during this process.
                </p>
                <p>
                    The concept of auto-renewal or auto-reordering would become a future goal for
                    building an even more expedited process for order placement year-over-year. A
                    systematic method of determining the identical course and subject combination
                    for the same erm the following year.
                </p>
                <p>
                    In the same way that Harry's handles auto-reminders and mail-out subscription
                    items, I would set a path for notifying users of an upcoming term, auto-generate
                    an order, and allow the users to change/cancel that scheduled order if need be.
                </p>
            </Text>
        </ProjectWrapper>
    );
}

const YoyPromo = styled.div``;

export default Reordering;
