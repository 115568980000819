import styled from 'styled-components';
import classnames from 'classnames';
import theme from '../theme';

function Text(props) {
    const { fullWidth, className, children } = props;
    const classes = classnames(`text px-8`, {
        'full-width': fullWidth,
        [`${className}`]: className,
    });
    return <SyledText className={classes}>{children}</SyledText>;
}

const SyledText = styled.div`
    max-width: 720px;
    margin: auto;

    &.full-width {
        max-width: 1440px;
    }

    ul,
    ol {
        list-style: auto;
        margin: ${theme.spacing.plus2} 0 ${theme.spacing.plus3} ${theme.spacing.plus4};
        li {
            padding-bottom: ${theme.spacing.plus2};
        }
    }
`;

export default Text;
