const spacing = {
    minus2: '1px', // minus 2
    minus1: '2px', // minus 1
    default: '4px', // default
    plus1: '8px', // plus 1
    plus2: '12px', // plus 2
    plus3: '20px', // plus 3
    plus4: '32px', // plus 4
    plus5: '52px', // plus 5
    plus6: '84px', // plus 6
    plus7: '136px', // plus 7
    plus8: '220px', // plus 8
    plus9: '356px', // plus 8
};

const font = {
    sizes: {
        minus4: '.6rem',
        minus3: '.7rem',
        minus2: '.8rem',
        minus1: '.9rem',
        default: '1rem',
        plus1: '1.2rem',
        plus2: '1.75rem',
        plus3: '2rem',
        plus4: '3rem',
        plus5: '5rem',
        plus6: '8rem',
        plus7: '13rem',
    },
};

const borderRadius = {
    default: '3px',
    plus1: '6px',
    plus2: '9px',
    round: '500px',
};

const colors = {
    gray: {
        light: 'hsla(0,0%,70%,1)',
        ultraLight: 'hsla(0,0%,95%,1)',
        dark: 'hsla(0,0%,15%,1)',
        ultraDark: 'hsla(0,0%,5%,1)',
    },
};

const breakpoints = {
    mobile: '375px',
    mobilePortrait: '375px',
    mobileLandscape: '576px',
    tablet: '768px',
    desktop: '1028px',
    desktopHD: '1440px',
};

const theme = {
    spacing,
    colors,
    breakpoints,
    borderRadius,
    font,
};

export default theme;
