import styled from 'styled-components';
import Section from './section';
import theme from '../theme';

function Quote(props) {
    const { children, submitter, position, className } = props;
    <Quote></Quote>;
    return (
        <StyledQuote className={className}>
            <div>
                <blockquote>{children}</blockquote>
                <Submitter>- {submitter}</Submitter>
                <Position> {position}</Position>
            </div>
        </StyledQuote>
    );
}

const Position = styled.div`
    padding-left: 20px;
    font-size: ${theme.font.sizes.plus1};
    line-height: ${theme.font.sizes.plus1};
    color: ${theme.colors.gray.light};
    margin-top: ${theme.spacing.plus1};
`;

const Submitter = styled.div`
    margin-top: ${theme.spacing.plus4};
    line-height: ${theme.font.sizes.plus2};
`;

const StyledQuote = styled(Section)`
    background: #111;
    border-top: 1px solid #333;
    border-bottom: 1px solid #333;
    font-family: 'Playfair Display', serif;
    font-size: ${theme.font.sizes.plus2};

    > div {
        max-width: ${theme.breakpoints.tablet};
        margin-left: auto;
        margin-right: auto;
    }

    blockquote {
        position: relative;
        z-index: 1;
        font-style: italic;

        :before,
        :after {
            content: '"';
            font-size: 196px;
            font-weight: bold;
            color: #fff;
            position: absolute;
            opacity: 15%;
            z-index: 0;
        }

        :before {
            top: 0;
            left: 0;
            transform: translate(-75%, -35%);
        }

        :after {
            bottom: 0;
            right: 0;
            transform: translate(75%, 60%);
        }
    }

    @media (max-width: ${theme.breakpoints.mobileLandscape}) {
        blockquote {
            padding-left: ${theme.spacing.plus4};
            padding-right: ${theme.spacing.plus4};

            :before,
            :after {
                font-size: 126px;
            }

            :before {
                transform: translate(20%, -30%);
            }

            :after {
                transform: translate(-50%, 60%);
            }
        }

        > div > div {
            padding-left: ${theme.spacing.plus4};
        }
    }
`;

export default Quote;
