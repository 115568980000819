import styled from 'styled-components';
import Text from './text';
import Section from './section';
import FadeInWhenVisible from './fade-in-when-visbile';
import Loading from './loading';
import Quote from './quote';
import theme from '../theme';
import utils from '../utils';

const indyImage = require('../assets/about/indy.png');
const fcImage = require('../assets/about/fort-collins.png');
const spaceNeedle = require('../assets/about/space-needle.png');

function About(props) {
    const classes = props.is_hero ? 'section hero' : 'section';
    const { aboutPhotos } = utils;

    return (
        <Loading kicker="Let's learn" text="A bit about Erik">
            <SyledAbout className={classes}>
                <Section className="md:mb-12">
                    <Text>
                        <h1>I am Erik Goens</h1>
                    </Text>
                    <Text>
                        <p className="text-2xl pt-4">
                            Over the course of my career, I have built a broad skill set that has
                            enabled me to bridge the gap between the world of design and
                            development. Not only do I enjoy the process of designing how interfaces
                            are intended to work, but I also take great pleasure in putting those
                            designs into action.
                        </p>
                    </Text>
                </Section>
                <Principles>
                    <h1>My guiding principles</h1>
                    <ImageFlex className="flex">
                        <div className="flex-none">
                            <Number className="flex-none">1</Number>
                        </div>
                        <div className="flex-1 justify-center flex flex-col">
                            <p className="text-2xl font-bold pb-1">
                                It’s important to focus on the problem first
                            </p>
                            <p>
                                The intention of design is to solve a problem. That problem needs
                                the attention it deserves. Fall in love with the problem, and an
                                effective result is much more likely to come straightforward than if
                                you focus solely on the solution alone.
                            </p>
                        </div>
                    </ImageFlex>
                    <ImageFlex className="flex flex-row-reverse">
                        <div className="flex-none">
                            <Number className="flex-none">2</Number>
                        </div>
                        <div className="flex-1 justify-center flex flex-col">
                            <p className="text-2xl font-bold pb-1">
                                Design is more than aesthetics
                            </p>
                            <p>
                                While I find great pleasure in the aesthetics of a well-defined
                                solution, the end solution must be functional first. Extraordinary
                                experiences are defined by providing the user the opportunity to
                                accomplish their goals with minimal resistance possible.
                            </p>
                        </div>
                    </ImageFlex>
                    <ImageFlex className="flex">
                        <div className="flex-none">
                            <Number className="flex-none">3</Number>
                        </div>
                        <div className="flex-1 justify-center flex flex-col">
                            <p className="text-2xl font-bold pb-1">I’m DRY through-and-through </p>
                            <p>
                                Earlier on in my career, I adopted the Don’t Repeat Yourself (DRY)
                                development mindset. I’ve carried this same mantra through my design
                                approach to this day. I strive to find efficacy through efficiency
                                in every facet of my work.
                            </p>
                        </div>
                    </ImageFlex>
                </Principles>
                <Quote submitter="Etienne Pelaprat" position="CTO / Unizin" className="mt-6 mb-6">
                    Erik and I worked together for 8 years. Over the last 3 years, Erik reported to
                    me. Erik led every aspect of our user experience design practice across a
                    challenging and diverse software portfolio. Over and above being a talent
                    designer, Erik is a design leader who naturally works well with his
                    cross-functional peers (PM, engineering, etc). He developed and we implemented a
                    full UX vision to life in our products. Erik complements his natural design
                    leadership skills and creativity with an unparalleled work ethic. He is an
                    effective contributor and produces high-quality work on time. He solicits and
                    accepts feedback well from his peers. He also educates and evangelizes a
                    creative, design-first culture. Any company would be lucky to have Erik on their
                    team. Beyond his individual contributions, he is a force multiplier in any
                    software organization.
                </Quote>
                <Section>
                    <h1 className="aural">My career development</h1>
                    <ImageFlex className="flex">
                        <div className="flex-1">
                            <FadeInWhenVisible>
                                <img src={indyImage} alt="" />
                            </FadeInWhenVisible>
                        </div>
                        <div className="flex-1 justify-center flex flex-col">
                            <p>
                                I'm originally from the midwest, and working remotely in Seattle,
                                WA. Let's take a step back and go through how I got to my current
                                career state.
                            </p>
                            <Job>
                                <div>
                                    <a href="https://indystar.com">The Indianapolis Star</a>
                                </div>
                                Senior Digital Designer / 2007-2010
                            </Job>
                            <p>
                                Once the economy went into shambles and the newspaper industry
                                folded (pardon the insensitive pun), I transitioned into working for{' '}
                                <a href="http://gannett.com">Gannett</a>, the parent company of
                                IndyStar. I then switched to focusing more on how users were
                                engaging with our products across 82 nationwide properties.
                            </p>
                        </div>
                    </ImageFlex>
                    <FadeInWhenVisible>
                        <ImageFlex className="flex flex-row-reverse">
                            <div className="flex-1">
                                <img src={fcImage} alt="" />
                            </div>
                            <div className="flex-1 justify-center flex flex-col">
                                <Job>
                                    <div>Courseload</div>
                                    Front-end Developer / 2012-2015
                                </Job>
                                <p>
                                    My next venture took me back into a smaller organization,
                                    Courseload, where I did front-end development and design of our
                                    reader application. As a team of two designers, we took on
                                    owning an evolving digital reading experiences across higher-ed
                                    institutions.
                                </p>
                                <p>
                                    In 2015 I moved from Indianapolis, IN, to Fort Collins, CO. This
                                    transition marked the starting point of my career as a remote
                                    employee.
                                </p>
                                <Job>
                                    <div>
                                        <a href="https://unizin.org">Unizin</a>
                                    </div>
                                    Senior Web Developer / 2015-2017
                                </Job>
                                <p>
                                    Once Unizin purchased the intellectual property of Courseload, I
                                    joined the Unizin team as the lone UX designer and researcher. I
                                    work with the support of a fantastic team redefining ways
                                    educational software can help shape the future of higher-ed.
                                </p>
                            </div>
                        </ImageFlex>
                    </FadeInWhenVisible>
                    <FadeInWhenVisible>
                        <ImageFlex className="flex">
                            <div className="flex-1">
                                <img src={spaceNeedle} alt="" />
                            </div>
                            <div className="flex-1 justify-center flex flex-col">
                                <Job>
                                    <div>
                                        <a href="https://unizin.org">Unizin</a>
                                    </div>
                                    Senior UX Designer / 2017-2021
                                </Job>
                                <p>
                                    Taking ownership of the design of all Unizin products has been
                                    an invaluable experience for my career. The opportunity to
                                    collaborate with product managers and engineering leads to plan,
                                    research, and execute product goals and business requirements
                                    has led to the successful production of applications and tools
                                    that serve our community members.
                                </p>
                                <Job>
                                    <div>
                                        <a href="https://thumbtack.com">Thumbtack</a>
                                    </div>
                                    {utils.title} / 2021-Current
                                </Job>
                                <p>
                                    At <a href="https://thumbtack.com">Thumbtack</a>, I've taken ownership as the sole dedicated design systems role for the design system <a href="https://thumbprint.design">Thumbprint</a>. This position has given me the opportunity to engage with a much larger crossfunctional audience and develop a product that truly scales.
                                </p>
                            </div>
                        </ImageFlex>
                    </FadeInWhenVisible>
                    {/* <FadeInWhenVisible>
                        <ImageFlex className="flex flex-row-reverse">
                            <div className="flex-1">
                                <img src={fcImage} alt="" />
                            </div>
                            <div className="flex-1 justify-center flex flex-col">
                                <Job>
                                    <div><a href="https://thumbtack.com">Thumbtack</a></div>
                                    {utils.title} / 2021-Current
                                </Job>
                                <p>
                                    In my 
                                </p>
                            </div>
                        </ImageFlex>
                    </FadeInWhenVisible> */}
                </Section>
                <Quote
                    submitter="Mike Packer"
                    position="Front-end Designer / Courseload"
                    className="mt-6 mb-6"
                >
                    Erik is about as well rounded and fully-skilled as they come for a front-end
                    designer and front-end developer. While he possesses the technical chops to
                    bring his ideas to life, his well balanced sense of design and usability shine
                    through the code and into the hands of the user.
                </Quote>
                <Section>
                    <Text className="pb-12">
                        <h2>Getting more personal</h2>
                        <p>
                            When I’m not working on improving UX, I’m yearning to get outside and
                            ride my Yeti SB5 mountain bike on the mountains of the Pacific
                            Northwest.
                        </p>
                        <p>
                            When I’m not on my bike, I’m looking forward to the next traveling
                            adventure with my incredible partner and our trail dog. To sum it up,
                            I’m an outdoor adventurist at heart, and software development is my
                            passion that funds and fuels my need for the trails.
                        </p>
                    </Text>

                    <PhotoGrid className="grid grid-cols-2">
                        {aboutPhotos.map((photo) => {
                            const image = require(`../assets/about/interests/${photo}`);
                            return (
                                <FadeInWhenVisible>
                                    <li>
                                        <img src={image} alt="" />
                                    </li>
                                </FadeInWhenVisible>
                            );
                        })}
                    </PhotoGrid>
                </Section>
                {/* <Section>
                    <Experience />
                </Section> */}
            </SyledAbout>
        </Loading>
    );
}
const numberSize = 175;
const Number = styled.div`
    font-family: 'Playfair Display', serif;
    font-size: ${numberSize / 2}px;
    line-height: ${numberSize / 2}px;
    color: #fff;
    display: flex;
    background-color: #111;
    border: 1px solid #333;
    border-radius: ${theme.borderRadius.round};
    width: ${numberSize}px;
    height: ${numberSize}px;
    align-items: center;
    justify-content: center;
    margin: auto;
`;

const Job = styled.div`
    padding-bottom: ${theme.spacing.plus3};

    div {
        font-weight: 900;
        font-size: ${theme.font.sizes.plus1};
        color: #fff;
    }

    a {
        text-decoration: none;

        :hover {
            text-decoration: underline;
        }
    }
`;

const PhotoGrid = styled.ul`
    margin: auto;
    max-width: ${theme.breakpoints.desktopHD};
    column-gap: ${theme.spacing.plus5};
    row-gap: ${theme.spacing.plus5};

    @media (max-width: ${theme.breakpoints.mobileLandscape}) {
        grid-template-columns: auto;
        row-gap: 0;
        column-gap: 0;

        li {
            padding: ${theme.spacing.plus3};
        }
    }
`;

const ImageFlex = styled.div`
    max-width: ${theme.breakpoints.desktop};
    margin: 0 auto ${theme.spacing.plus6} auto;

    p:not(.font-bold) {
        color: #ccc;
    }

    :last-of-type {
        margin-bottom: 0;
    }

    .flex-1 {
        padding: ${theme.spacing.plus4};
    }

    &.flex-row-reverse {
        .flex-1:last-child {
            padding-right: 0;
        }
    }

    @media (max-width: ${theme.breakpoints.tablet}) {
        flex-direction: column;

        p {
            max-width: 100%;
        }

        img {
            max-width: 50%;
            margin: auto;
        }
    }

    @media (max-width: ${theme.breakpoints.mobileLandscape}) {
        img,
        p {
            max-width: 100%;
        }
    }
`;

const SyledAbout = styled(Section)`
    padding: ${theme.spacing.plus7} 0;
    position: relative;
    z-index: 1;

    h2 {
        font-size: ${theme.spacing.plus4};
        line-height: ${theme.spacing.plus4};
        padding-bottom: 0;
        margin: ${theme.spacing.plus5} 0 ${theme.spacing.plus4};
        color: ${theme.colors.gray.ultraLight};
    }

    img {
        border-radius: ${theme.borderRadius.default};
    }
`;

const Principles = styled(Section)`
    border-top: 1px solid #333;
    padding-top: ${theme.spacing.plus6};

    h1 {
        font-family: 'Playfair Display', serif;
        font-weight: 500;
        max-width: ${theme.breakpoints.desktop};
        margin: 0 auto ${theme.spacing.plus6} auto;
    }

    p:not(.font-bold) {
        padding-right: ${theme.spacing.plus7};
    }

    @media (max-width: ${theme.breakpoints.mobileLandscape}) {
        h1 {
            padding-left: ${theme.spacing.plus4};
        }

        p:not(.font-bold) {
            padding-right: ${theme.spacing.plus4};
        }
    }
`;

export default About;
