import Text from '../text';
import Section from '../section';
import Figure from '../figure';
import ProjectWrapper from './project-wrapper';
import ExternalLinkPromo from './external-link-promo';

function UnizinLogoRedesign(props) {
    const {
        project: { slug, primaryColor },
    } = props;

    return (
        <ProjectWrapper slug={slug}>
            <Text>
                <p>
                    How do you know when it’s time to make a change to your visual brand? There
                    really aren’t any hard and fast rules for how and when you should evaluate
                    making changes to how people perceive your business. Making an adjustment for
                    the sake of change is not valid reasoning to rebrand. There should be reason and
                    purpose behind that change.
                </p>
            </Text>
            <Section>
                <Figure
                    slug={slug}
                    fileName="unizin-logo-reversed.png"
                    alt="Unizin logo"
                    caption="The new unizin logo"
                />
            </Section>
            <Text>
                <h2 className="text-3xl pb-4">Problem to be solved</h2>
                <p>
                    Most of anyone who we shared our logo with or who has seen our logo for the
                    first time assumes that we are a winery or a part of wine distribution in some
                    regards.
                </p>
                {/* {image of logo} */}
                <p>
                    While the grapes did provide an interesting concept and help Provide a bit of
                    intention behind the goals of our organization, they do struggle to personify
                    who we are and what we do. That’s not to say that we can’t get better with time
                    but we are better served by expressing our digital and technical footing through
                    our visual identity.
                </p>
            </Text>
            <Text>
                <h2 className="text-3xl pb-4">Redesign our logo</h2>
                <h3 className="text-2xl pb-4">Color choice</h3>
                <p>
                    The colors used in our design came from our brand guidelines. We leveraged our
                    new primary purple hue and paired it with our secondary fuschia color. The two
                    hues combined made for a rich yet subtle gradient transition throughout. You can
                    see those gradations playing out in the each element of the logo from the
                    graduation cap, down to the visual data elements at the base of the glyph.
                </p>
                {/* {add basic color palette from Behance } */}
            </Text>
            <Text>
                <h3 className="text-2xl pb-4">Designing the glyph</h3>
                <p>
                    Redesigning this element was the bulk of consideration and time spent on our new
                    approach. This is the visual that would be the deepest representation of our
                    brand. The element would be present in all permutations including the
                    horizontal, vertical, monochromatic logos and all the down to the mobile
                    application icons and website favicons.
                </p>
            </Text>
            <Section>
                <Figure
                    slug={slug}
                    fileName="sketches.png"
                    alt="Sketch of glyph options"
                    caption="Sketch of glyph options"
                />
            </Section>
            <Text>
                <p>
                    This visual needed a refresh that gave the consumers of our product a better
                    sense of the tech space we occupy. While the logo did not need to explicitly
                    express what we do, giving a nod to why we exist became highly desirable.
                </p>
                <p>
                    While not everyone is aware of the nuances of certain larger brand logos, its
                    always a pleasure seeing the responses once they’re alerted to the details that
                    otherwise go unseen. For example, when the arrow in the FedEx logo was brought
                    to the leadership team’s attention it was breath of fresh air knowing they would
                    see the value in adding or own personal touch to our new design.
                </p>
            </Text>
            <Text>
                <h3 className="text-2xl pb-4">Choosing the right font</h3>
                <p>
                    Fonts play an integral role in the visual definition of a brand. From the
                    cursive and whimsical Disney, to the heavy-weighted characters of the Metallica
                    logo, and every variation in-between, you're choice of font should play an
                    assisting role on how your brand is perceived.
                </p>
                <p>
                    With a refresh in mind, we wanted to select a font that gave the immediate sense
                    of a modern company that fits into the center of tech space. Even though we are
                    involved in the higher-ed space, it was not a requirement that we appeal to
                    collegiate mindset or construct a mental connection to a more traditional or
                    institutional identification.
                </p>
                <p>
                    After much deliberation and research the Neue Kabel font from Adobe became the
                    type of choice. This font reflected the nature of upward movement and trajectory
                    in the ascenders and also more effectively reflects the business space that we
                    occupy. The hard angles also mimicked the sharp edges of the graduation cap in
                    the glyph while being well paired with a rounded shapes on the font base and
                    glyph base.
                </p>
            </Text>
            <Text>
                <ExternalLinkPromo
                    primaryColor={primaryColor}
                    url="https://www.behance.net/gallery/116709909/Unizin-Logo-Redesign"
                    source="Behance"
                    kicker="View on Behance"
                    text="To see more images and a collective storyline, check out this project on my Behance profile."
                />
            </Text>
        </ProjectWrapper>
    );
}

export default UnizinLogoRedesign;
