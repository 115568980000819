import Text from '../text';
import ProjectWrapper from './project-wrapper';

function LatinAt(props) {
    const { slug } = props;

    return (
        <ProjectWrapper slug={slug}>
            <Text>Some text</Text>
        </ProjectWrapper>
    );
}

export default LatinAt;
