import styled from 'styled-components';
import classnames from 'classnames';

function Section(props) {
    const { className, children, isHero, isCentered, isFullWidth } = props;

    const classes = classnames(`section py-16`, {
        'hero flex': isHero,
        centered: isCentered,
        'full-width': isCentered | isFullWidth,
        [`${className}`]: className,
    });
    return <SyledSection className={classes}>{children}</SyledSection>;
}

const SyledSection = styled.section`
    margin-left: auto;
    margin-right: auto;

    &.hero {
        min-height: 100vh;
        align-content: center;

        > div {
            flex: 1;
            margin: auto;
        }
    }

    &.full-width {
        padding-left: 0;
        padding-right: 0;
    }
`;

export default Section;
