import styled from 'styled-components';
import classnames from 'classnames';
import ProjectTeaser from './project-teaser';
import Section from '../section';
import theme from '../../theme';
import utils from '../../utils';

function ProjectTeasers(props) {
    const classes = classnames('teasers', {
        hero: props.isHero,
        small: props.isSmall,
        'full-width': props.isFullWidth,
        'horizontal-scroll': props.isHorizontalScroll,
        [`${props.format}`]: props.format,
    });
    const { projects } = utils;
    const featured_projects = projects.filter((project) => project.featured);
    const other_projects = projects.filter((project) => !project.featured);

    function renderProjects(projects) {
        const is_featured = projects.find((project) => project.featured);
        const classes = classnames('projects', {
            featured: is_featured,
        });

        return (
            <Projects className={classes}>
                <h1 className="px-8 py-4 aural">
                    {is_featured ? 'Featured projects' : 'All other projects'}
                </h1>
                <ul>
                    {projects.map((project) => (
                        <ProjectTeaser
                            key={project.slug}
                            slug={project.slug}
                            isSmall={props.isSmall}
                            handleClick={props.handleClick}
                        />
                    ))}
                </ul>
            </Projects>
        );
    }

    return (
        <Teasers className={classes}>
            {renderProjects(featured_projects)}
            {renderProjects(other_projects)}
            {/* <Button className="no-border" to="work">
                View all projects
            </Button> */}
        </Teasers>
    );
}

// const Button = styled(Link)`
//     display: inline-block;
//     background: red;
//     padding: ${theme.spacing.plus2} ${theme.spacing.plus3};
//     font-size: ${theme.font.sizes.plus1};
//     border-radius: ${theme.borderRadius.default};
// `;

const Projects = styled.div`
    max-width: ${theme.breakpoints.desktop};
    margin: ${theme.spacing.plus6} auto ${theme.spacing.plus6} auto;

    ul {
        list-style: none;
        margin: 0 0 ${theme.spacing.plus4} 0;
        padding: 0;
        display: grid;
        grid-template-columns: repeat(4, minmax(200px, 1fr));

        div a {
            min-height: 200px;
            border-radius: ${theme.borderRadius.default};
        }

        div h2 a {
            min-height: auto;
        }

        h2 {
            padding: ${theme.spacing.plus3} 0 ${theme.spacing.plus1};
            font-size: ${theme.font.sizes.plus1};
            margin: 0;

            a:after {
                background: transparent;
            }

            a:hover:after {
                background: hsl(0, 0%, 100%);
            }
        }
    }

    &.featured {
        ul {
            // grid-template-columns: repeat(
            //     auto-fit,
            //     minmax(${theme.breakpoints.mobilePortrait}, 1fr)
            // );
            grid-template-columns: repeat(2, 1fr);
            grid-gap: ${theme.spacing.plus4};
            align-items: start;

            div a {
                min-height: 300px;
            }

            h2 {
                font-size: ${theme.font.sizes.plus3};

                + div {
                    font-size: ${theme.font.sizes.plus1};
                }
            }

            // sets first project at 100% width
            li:first-child {
                grid-column: 1 / -1;

                div a {
                    min-height: 500px;
                }
            }
        }
    }

    :not(.featured) {
        h1 {
            font-size: ${theme.font.sizes.plus1};
        }
    }

    @media (max-width: ${theme.breakpoints.mobileLandscape}) {
        ul {
            grid-template-columns: repeat(
                auto-fit,
                minmax(${theme.breakpoints.mobilePortrait}, 1fr)
            );
            grid-gap: ${theme.spacing.plus4};
        }

        div a {
            min-height: 300px;
        }
    }

    @media (min-width: ${theme.breakpoints.desktop}) {
        &.featured ul div a {
            min-height: 400px;
        }

        &.featured ul li:first-child div a {
            min-height: 700px;
        }
    }
`;

const Teasers = styled(Section)`
    max-width: ${theme.breakpoints.desktopHD};

    h1 {
        font-size: ${theme.spacing.plus4};
        line-height: ${theme.spacing.plus4};
        max-width: ${theme.breakpoints.desktopHD};
        margin: auto;
    }

    &.hero {
        display: flex;
        justify-content: center;

         > div {
             flex: 1;
             margin: auto;
         }
    }

    &.horizontal-scroll {
        ul {
            display: grid;
            grid-gap: 10px;
            grid-template-columns: repeat(auto-fill, min(100px));
            grid-template-rows: minmax(150px, 1fr);   
        }

        &.small {
            ul li div {
                max-height: 75px;
            }
        }
    }
    

    &.row {
        ul {
            grid-auto-flow: column;
        }

        &.small {
            ul {
                grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
            }
        }
    }

    &.small {
        h1 {
            font-size: ${theme.font.sizes.plus1};
            line-height: ${theme.font.sizes.plus1};
        }
    }

    @media (max-width: ${theme.breakpoints.mobileLandscape}) {
        ul {
            grid-gap: ${theme.spacing.plus5};

            li {
                padding: 0;
            }
        }

        &.full-width ul {
            grid-template-columns: repeat(1, minmax(${theme.breakpoints.mobilePortrait}, 1fr));
        }
    })
`;

export default ProjectTeasers;
