import ReactGA from 'react-ga';

// process on how to apply GA was found here:
// https://raptis.wtf/blog/custom-hook-to-connect-google-analytics-in-react/

const TRACKING_ID = 'UA-6122577-2';

function init() {
    // Enable debug mode on the local development environment
    const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
    ReactGA.initialize(TRACKING_ID, { debug: isDev });
}

function sendEvent(payload) {
    ReactGA.event(payload);
}

function sendPageview(path) {
    ReactGA.set({ page: path });
    ReactGA.pageview(path);
}

const GAGoodness = {
    init,
    sendEvent,
    sendPageview,
};

export default GAGoodness;
