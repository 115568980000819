import styled from 'styled-components';
import ProjectPagination from './project-pagination';
import Project from './project';
import utils from '../../utils';

import theme from '../../theme';

function ProjectWrapper(props) {
    const { slug, children } = props;
    const { projects } = utils;
    const project = projects.find((item) => {
        return item.slug === slug;
    });

    return (
        <>
            <StyledProject project={project}>{children}</StyledProject>
            <ProjectPagination slug={slug} projects={projects} />
        </>
    );
}

const StyledProject = styled(Project)`
    .flex-row li {
        min-width: 200px;
        max-width: 250px;
        font-size: 1rem;
    }

    figcaption {
        padding-top: ${theme.spacing.plus2};
        font-size: ${theme.font.sizes.minus2};
        text-align: center;
    }

    @media (max-width: ${theme.breakpoints.tablet}) {
        .grid.row {
            padding: 0;
            column-gap: ${theme.spacing.plus1};
        }
    })
`;

export default ProjectWrapper;
