import styled from 'styled-components';
import theme from '../../theme';

function ExternalLinkPromo(props) {
    const { primaryColor, url, source, kicker, text } = props;
    const image_path = source
        ? require(`../../assets/social/${source.toLowerCase()}.png`).default
        : null;

    return (
        <LinkPromo
            className="external-link-promo no-border"
            href={url}
            style={{ background: primaryColor }}
        >
            <div className="flex items-center">
                {source ? (
                    <div className="flex-none pr-4">
                        <img src={image_path} alt={`${source} logo`} />
                    </div>
                ) : (
                    ''
                )}
                <div className="flex-1">
                    <Kicker>{kicker}</Kicker>
                    <div>{text}</div>
                </div>
            </div>
        </LinkPromo>
    );
}

const LinkPromo = styled.a`
    background: #111;
    border: 1px solid #222;
    padding: ${theme.spacing.plus3};
    margin: ${theme.spacing.plus4} 0;
    display: block;
    border-radius: ${theme.borderRadius.plus1};

    &:hover {
        background: #222;
        border-color: #333;

        div.flex-1 div:last-of-type {
            text-decoration: underline;
        }
    }

    :after {
        display: none;
    }

    :visited {
        color: #fff;
    }

    img {
        max-width: 64px;
    }
`;

const Kicker = styled.div`
    text-transform: uppercase;
    font-size: ${theme.font.sizes.minus2};
    line-height: ${theme.font.sizes.default};
    font-weight: normal;
    letter-spacing: 1px;

    + div {
        padding-top: ${theme.spacing.plus1};
        // line-height: ${theme.font.sizes.plus1};
    }
`;

export default ExternalLinkPromo;
