import styled from 'styled-components';
import { motion, useTransform, useScroll } from 'framer-motion';
import Section from './section';
import Intro from './intro';
import ProjectTeasers from './projects/project-teasers';
import Experience from './experience';
import theme from '../theme';
import Loading from './loading';
import erikImage from '../assets/about/me.png'
import utils from '../utils';

function Home(props) {
    const { scrollY } = useScroll();
    const scrollYHeroOpacity = useTransform(scrollY, [0, 1000], [0, 0.275]);
    const scrollYHeroPosition = useTransform(scrollY, [0, 850], ['0', '4%']);
    return (
        <Loading kicker="Looks like we're" text="Homeward bound">
            <SyledHome>
                <Intro />
                <Section isHero>
                    <motion.img
                        src={erikImage}
                        alt="Erik Goens wearing a hat"
                        style={{ opacity: scrollYHeroOpacity, left: scrollYHeroPosition }}
                    />
                    <div className="max-w-screen-md px-8">
                        <p className="text-2xl">
                            Hello, my name is Erik Goens. I am a {utils.title} at{' '}
                            <a href="https://thumbtack.com">Thumbtack</a>. My mission is to apply my design and engineer expertise
                             into a team environment to collaborate with fellow
                            teammates to design rich and engaging user experiences.
                        </p>
                        <p className="text-2xl">
                            Over the course of my career, I have built a broad skill set that has
                            enabled me to bridge the gap between the world of design and
                            development. Not only do I enjoy the process of designing how interfaces
                            are intended to work, but I also take great pleasure in putting those
                            designs into action.
                        </p>
                    </div>
                </Section>
                <ProjectTeasers isFullWidth />
                <StyledExperience />
            </SyledHome>
        </Loading>
    );
}

const SyledHome = styled.section`
    margin: auto;

    .text p {
        font-weight: 600;
    }

    @media (max-width: ${theme.breakpoints.mobileLandscape}) {
        .text h1 {
            font-size: ${theme.font.sizes.plus3};
        }

        .teasers {
            padding: ${theme.spacing.plus4} 0;
        }
    }

    .hero {
        position: relative;

        img {
            position: absolute;
            z-index: 0;
            top: 50%;
            max-width: 25vw;
            transform: translateY(-50%);
            filter: grayscale(1);
            transition: all 0.33s;

            :hover {
                filter: grayscale(0);
                opacity: 50% !important;
            }
        }

        p {
            position: relative;
            z-index: 1;
        }
    }

    @media (max-width: ${theme.breakpoints.desktop}) {
        section.hero img {
            max-width: 50vw;
        }
    }
`;

const StyledExperience = styled(Experience)`
    padding-bottom: ${theme.spacing.plus7};
`;

export default Home;
