import FadeInWhenVisible from '../fade-in-when-visbile';
import Text from '../text';
import Section from '../section';
import ProjectWrapper from './project-wrapper';
import Figure from '../figure';
import Tabs from '../tabs';

function UnizinDesignSystem(props) {
    const {
        project: { slug, primaryColor },
    } = props;

    const tabs = [
        { title: 'Book covers', caption: 'A few examples of cover permutations' },
        { title: 'Right rail', caption: 'Rail rail options based on roles and conditionals' },
    ];

    return (
        <ProjectWrapper slug={slug}>
            <Text>
                <p>
                    At Unizin, all of our products share a unique set of problems, but all are bound
                    by a common thread meeting the needs of higher education. Not all are the same,
                    but enough similarities exist across our tools and applications to warrant
                    focussing a common set of interfaces and designs. As a result, I focused on
                    designing a set of visuals that would become our design system.
                </p>
            </Text>
            <Text>
                <h2 className="text-3xl pb-4">Problem to be solved</h2>
                <p>
                    As we began evaluating the need to update our products, we were at a crossroads
                    for assessing how we should move forward. Would it make the most sense to sit on
                    our legacy platform and update our existing codebase to fit our evolution?
                    Should we consider starting from scratch and implementing a brand new solution
                    on a more modern stack? Perhaps there was a middle ground. How could we leverage
                    existing work to reduce our overhead and focus on updating the core structures
                    that need the most attention?
                </p>
                <p>
                    Why not create a <b>design system</b> that can leverage across our applications
                    to act as a platform for any new applications that might enter our arsenal?
                </p>
                <p>
                    As a designer, my primary role isn't to focus on the aesthetics of these
                    components. My core responsibility is to solve problems at hand. To fall in love
                    with the problem, and find the best solution to solve that problem. The form
                    will then follow the function. Our design system should be the common ground to
                    just that. Think about function first.
                </p>
            </Text>
            <Text>
                <h2 className="text-3xl pb-4">Where to start?</h2>
                <p>
                    I started by applying the basic ground rules governed by the Unizin brand
                    guidelines. Our <b>brand guidelines</b> are a set of general rules given to the
                    product team. While these rules could apply across digital and print work
                    boundaries, we focused exclusively on our digital product line for the{' '}
                    <b>design system</b>.
                </p>
                <p>
                    Borrowing from our <b>brand guidelines</b>, we set rules such as color,
                    emotional tone, visual tone and apply those to the design system. However, these
                    were only rudimentary. I needed to think beyond the basics and consider the next
                    evolutionary stage of our <b>design system</b>.
                </p>
            </Text>
            <Section>
                <Figure
                    slug={slug}
                    fileName="brand-guidelines.png"
                    alt="Unizin brand guidelines"
                    caption="Unizin brand guidelines"
                />
            </Section>
            <Text>
                <h2 className="text-3xl pb-4">Defining our design tokens</h2>
                <p>
                    Understanding what font to use and when to apply it is the start of applying a
                    <b>brand guideline</b> to a <b>design system</b>. It's also important to
                    consider visual balance and consistent measurements that will scale across
                    devices and provide the most pleasant and legible experience.
                </p>
                <p>
                    I needed to think about what elements would be the foundational structures that
                    would govern the scale & balance from various elements and standards such as
                    padding, margins, shadows, borders, etc. I needed to focus on the{' '}
                    <b>design tokens</b>.
                </p>
            </Text>
            <Text>
                <h3 className="text-2xl pb-4">Spacing</h3>
                <p>
                    I began by applying a definition of spacing that adheres to a{' '}
                    <b>Golden Ratio</b> of spacing that starts at a figure of 4. Meaning we would
                    include preceding figures of 4 at sparingly and rare instances, but the core
                    spacing scale of 4, 8, 12, 20, 32, 52, 84, and so on. To read more about the
                    value and insights on this approach, here is a link to a great article written
                    by CodyHouse’s Co-Founder{' '}
                    <a href="https://medium.com/@guerriero_se">Sebastiano Guerriro</a> on how to{' '}
                    <a href="https://medium.com/codyhouse/create-your-design-system-part-4-spacing-895c9213e2b9">
                        Create your design system, part 4: Spacing
                    </a>
                    .
                </p>
                <Figure
                    slug={slug}
                    fileName="golden-rule.png"
                    alt="Golden Rule of spacing that starts at a figure of 4"
                    caption="Golden Rule of spacing that starts at a figure of 4"
                />
            </Text>
            <Text>
                <h3 className="text-2xl pb-4">Typography</h3>
                <p>
                    I focused on developing a framework for applying a set of typography font faces,
                    weights, sizes, and line heights. This framework became a collection of rules
                    that provide the most legible and structural balance used at the paragraph level
                    and defines usage in other elements such as heroes, headings, kickers, etc.
                </p>
                <p>
                    These rules needed to consider the breadth and depth of content where they are
                    applied—for example, thinking about line-height, weight, and spacing for headers
                    independently of what type is applicable in a body of text. The legibility of a
                    long-range of text at a taller line height allows the eye to scan across
                    multiple lines of smaller text. Inversely, headings will be more concise and
                    likely have a heavier weight and larger font size. Therefore, these elements
                    should have a smaller line height applied.
                </p>
                <p>
                    Once defined, it was time to apply that to our existing design system by
                    creating a set of styles to a design file that we would use as our typography
                    rule sets. This file can be imported into any other design file to inherit these
                    styles and allow for an easy transition into a collection of consistent and
                    aesthetic use of typographic elements.
                </p>
            </Text>
            <Text>
                <h2 className="text-3xl pb-4">ATOMic Design Principles</h2>
                <p>
                    After establishing the basics of fonts and spacing, I began to inventory the
                    most commonly used portions of our tools and think of our components on the
                    <b>ATOMic design</b> level. Focusing first on the atoms of our application with
                    essential elements such as buttons, form elements, icons, and any other
                    component could live in isolation.
                </p>
            </Text>
            <Text>
                <h3 className="text-2xl pb-4">Atoms</h3>
                <p>
                    I viewed <b>atoms</b> as our most critical set of components, and they would
                    have the most variants applied and used much more frequently than other atomic
                    elements. These <b>atoms</b> will make up the slightly more complex molecular
                    components.
                </p>
            </Text>
            <Section>
                <Figure
                    slug={slug}
                    fileName="atoms.png"
                    alt="Examples of our design system atom elements"
                    caption="Examples of our design system atom elements"
                    background="#222"
                />
            </Section>
            <Text>
                <h3 className="text-2xl pb-4">Molecules</h3>
                <p>
                    After establishing a set of <b>atoms</b>, the focus shifted to combining those
                    elements into a reusable set of <b>molecules</b>. How can we pair together our
                    atomic elements to form a group of functional components without introducing too
                    much complexity?
                </p>
                <p>
                    A prime example of what we consider a molecule is the representation of a book
                    cover. As an organization, we focused heavily on the adoption and delivery of
                    course materials. The reuse of scalable cover images is crucial for our users'
                    consumption. The cover image could be considered an atom at its core, but the
                    combination of material type, title, edition, size, and type combined to form
                    the entire cover molecule.
                </p>
                <p>
                    As mentioned before, it's essential to think about the flexibility and
                    reusability of these elements. Molecules will most likely be the second most
                    used element within our design system. They're intended to be highly portable
                    and can apply to various scenarios, including within a comprehensive set of
                    <b>organisms</b>.
                </p>
            </Text>
            <FadeInWhenVisible>
                <div className="px-8">
                    <Tabs tabs={tabs} project="unizin-design-system" primaryColor={primaryColor} />
                </div>
            </FadeInWhenVisible>
            <Text>
                <h3 className="text-2xl pb-4">Organisms</h3>
                <p>
                    Next, we focused on establishing the groupings of molecules and <b>atoms</b> to
                    form our organisms. These became representations of items such as a single
                    course result, pricing details, page headers, footer, etc.
                </p>
                <p>
                    The frequency of use for these elements would be lesser than its predecessors,
                    but their importance elevated the experience by providing more informed
                    contextual structures. The culmination of these elements would produce our
                    <b>templates</b>.
                </p>
            </Text>
            <Section>
                <Figure
                    slug={slug}
                    fileName="organisms.png"
                    alt="Small sample of organism components"
                    caption="Small sample of organism components"
                    background="#222"
                />
            </Section>
            <Text>
                <h3 className="text-2xl pb-4">Templates</h3>
                <p>
                    <b>Templates</b> are where the real intention of the UI comes into shape. These
                    visuals start to represent how our users begin to solve problems. For example,
                    let's assume we want to focus on how users might see a list of their courses.
                </p>
                <p>
                    The makeup of previously defined elements would comprise a <b>template</b> for
                    course listings. Listings are paginated based on the user's selection for their
                    batch ranges. They can select from an option of 10, 25, 50, and 100 results.
                    Course listings are how our users view and interact with their teaching
                    assignments.
                </p>
            </Text>
            <Section>
                <Figure
                    slug={slug}
                    fileName="templates.png"
                    alt="Small sample of organism components"
                    caption="Small sample of organism components"
                    background="#fff"
                />
            </Section>
            <Text>
                <h3 className="text-2xl pb-4">Pages</h3>
                <p>
                    Lastly, we were able to turn our focus to page-level elements. These designs are
                    comprised of all our previous design elements and represent the vast majority of
                    our application’s page views. These are pages such as login, search results,
                    order history, and schedule of classes.
                </p>
                <p>
                    Having designs at this level enabled us to generate <b>prototypes</b> for
                    testing and demonstration purposes quickly.
                </p>
            </Text>
            <Section>
                <Figure
                    slug={slug}
                    fileName="pages.png"
                    alt="Small sample of organism components"
                    caption="Small sample of organism components"
                />
            </Section>
            <Text>
                <h2 className="text-3xl pb-4">Lessons learned</h2>
                <p>
                    Each of these <b>atomic elements</b> was designed using <b>Figma libraries</b>{' '}
                    and imported by each respective parent library. Using this structure means we
                    can trickle up changes when a design element changes all the way down at the
                    atom level.
                </p>
                <p>
                    While it's of the utmost importance to reflect the intended use of each{' '}
                    <b>component</b>, it's also essential to express the unintended use—the do's and
                    don'ts when and where it feels most applicable.
                </p>
                <p>
                    File organization is also critical. Even as a team of one designer, it's crucial
                    to have a clearly structured and easily referenceable format for your design
                    elements, from frame naming, style hierarchy, and canvas arrangement.
                </p>
            </Text>
            <Text>
                <h2 className="text-3xl pb-4">Forever changing</h2>
                <p>
                    I've been excited to see this system grow and equally as excited to see it
                    mature. Our <b>design system</b> continues to evolve, and this feels like a
                    natural part of the process. As natural as the ever-changing landscape of the
                    design tools that support these systems. The transition from{' '}
                    <b>Sketch to Figma</b> has been quite a journey, and a big shout out to the
                    Figma team for creating a well-developed Sketch import tool.
                </p>
            </Text>
        </ProjectWrapper>
    );
}

export default UnizinDesignSystem;
