// import { withRouter } from 'react-router-dom';
import { useNavigate } from "react-router";
// import withRouter from '../utils';
import React from 'react';
import styled from 'styled-components';
import classnames from 'classnames';
import theme from '../theme';
import Section from './section';
import Navigation from './navigation';

// const NavigationMenu = ({ history }) => {
class NavigationMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = { active: false };
    }

    closeModal = () => {
        this.setState({ active: false });
        document.body.classList.remove('overflow-hidden');
    };

    // need to move events into a component that unmounts
    // in it's current state, this component never gets removed from the dom and
    // will always trigger a key event
    // remove console log after this is resolved
    keyBoardEvents = (event) => {
        if (event.keyCode === 27) {
            this.closeModal();
        }
    };

    // listens to changes to url and closes overlay on change
    // componentDidUpdate(prevProps) {
    //     if (prevProps.location.pathname !== this.props.location.pathname) {
    //         this.closeModal();
    //     }
    //     this.closeModal();
    // }

    componentDidMount() {
        document.addEventListener('keydown', this.keyBoardEvents, false);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.keyBoardEvents, false);
    }

    handleOverflowClass = () => {
        this.state.active
            ? document.body.classList.remove('overflow-hidden')
            : document.body.classList.add('overflow-hidden');
    };

    handleClick = () => {
        this.setState((state) => ({
            active: !state.active,
        }));
        this.handleOverflowClass();
    };

    render() {
        const navClasses = classnames({
            'is-active': this.state.active,
        });
        const buttonClasses = classnames('hamburger hamburger--squeeze', {
            'is-active navigation-button': this.state.active,
        });
        return (
            <SyledNavigationMenu className={navClasses} onKeyPress={this.keyBoardEvents}>
                <button
                    className={buttonClasses}
                    type="button"
                    onClick={this.handleClick}
                    aria-label="Navigation menu"
                >
                    <span className="hamburger-box">
                        <span className="hamburger-inner"></span>
                    </span>
                </button>
                <NavigationContentWrapper className={navClasses}>
                    <NavigationContent className={navClasses}>
                        <h1 className="aural">Menu</h1>
                        <Navigation handleClick={this.handleClick} hideProjects hideProjectLinks />
                    </NavigationContent>
                </NavigationContentWrapper>
            </SyledNavigationMenu>
        );
    }
}

const SyledNavigationMenu = styled.nav`
    &.is-active {
        height: 100vh;
    }

    button {
        position: fixed;
        top: ${theme.spacing.plus5};
        right: ${theme.spacing.plus5};
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 3;

        .hamburger-inner {
            background: transparent;

            &:before,
            &:after {
                border-radius: 0;
            }

            &:before {
                top: -5px;
                width: 25px;
                right: 0;
            }
        }
    }

    @media (max-width: ${theme.breakpoints.tablet}) {
        button {
            top: ${theme.spacing.plus3};
            right: ${theme.spacing.plus3};
        }
    }
`;

const NavigationContentWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100vw;
    height: 1px;
    background: ${theme.colors.gray.ultraDark};
    transition: all 0.25s;

    &.is-active {
        height: 100vh;
    }
`;

const NavigationContent = styled(Section)`
    display: none;
    height: 100vh;
    align-items: center;
    padding: 0;

    &.is-active {
        display: grid;
    }

    h1 {
        font-size: ${theme.spacing.plus3};
        text-transform: uppercase;
        font-weight: 700;
    }

    .nav-links {
        li {
            padding-bottom: ${theme.spacing.plus4};

            a {
                font-size: ${theme.spacing.plus6};
                line-height: ${theme.spacing.plus6};
                font-weight: 900;
                letter-spacing: -1px;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }

            ul {
                margin-top: ${theme.spacing.plus4};

                li a {
                    font-size: ${theme.spacing.plus3};
                    line-height: ${theme.spacing.plus3};
                }
            }
        }

        @media (max-width: ${theme.breakpoints.tablet}) {
            li a {
                font-size: ${theme.spacing.plus5};
                line-height: ${theme.spacing.plus5};
            }
        }
    }

    .teasers {
        a {
            &:hover,
            &:focus,
            &:active {
                transform: scale(1.025);
                color: ${theme.colors.gray.light};
            }
        }
    }
`;

const withRouter = (Component) => {
	const Wrapper = (props) => {
		const history = useNavigate();
		return <Component history={history} {...props} />;
	};
	return Wrapper;
};

export default withRouter(NavigationMenu);
