import Text from '../text';
import ProjectWrapper from './project-wrapper';
import Figure from '../figure';
import Section from '../section';

function UnizinBrandGuidelines(props) {
    const {
        project: { slug },
    } = props;

    return (
        <ProjectWrapper slug={slug}>
            <Text>
                <p>
                    Unizin is a higher-education consortium comprised of many universities with the
                    same aspirational goals of providing better digital solutions that improve
                    educational experiences, yield better student outcomes, and save their students
                    money. There is no magic bullet or a single piece of software that can achieve
                    those goals alone.
                </p>
                <p>
                    To move the needle toward success, we needed to consider how to build solutions
                    for each need. We also needed to think about how those products would be
                    identifiable or recognizable to all users of our products. No matter what the
                    frequency of visitation.
                </p>
            </Text>

            <Text>
                <h2 className="text-3xl pb-4">Problem to be solved</h2>
                <p>
                    Before we moved into considering redesigning our logo, our current brand
                    identity lacked the representation that it deserved. There was previously no
                    formalized documentation outlining how our assets, voice, visuals and mission
                    should be applied to any Unizin communications. Knowing that we were moving
                    forward with a logo redesign project, the timing was ripe for constructing a
                    more formalized brand identity and brand guideline.
                </p>
                <p>
                    Knowing that we were moving forward with a logo redesign project, the timing was
                    ripe for constructing a more formalized brand identity and brand guideline.
                </p>
            </Text>
            <Section>
                <Figure
                    slug={slug}
                    fileName="unizin-brand-guidelines.png"
                    caption="Unizin brand guidelines"
                />
            </Section>
            <Text>
                <h2 className="text-3xl pb-4">Starting with color</h2>
                <h3 className="text-2xl pb-4">Primary colors</h3>
                <p>
                    To better understand the perception of the color used in our current brand, I
                    surveyed to get a broader perception from different perspectives. The intention
                    was to determine if there was a need to pivot from our primary colors entirely
                    or if there was enough interest in staying in the same vein of the purple hue.
                </p>
                {/* {insert image of survey outcomes} */}
                <p>
                    The results showed a lot of interest in retaining the purple hue as one of our
                    primary colors. I was personally pleased with this outcome as I was interested
                    in keeping this hue but perhaps with an updated twist. The psychology of colors
                    suggests that purple gives off a sense of trust, stability, and royalty. Think
                    about the king’s cloak or a Crown Royal bag.
                </p>
            </Text>
            <Section>
                <Figure
                    slug={slug}
                    fileName="primary-colors.png"
                    caption="Unizin brand guideline primary colors"
                />
            </Section>
            <Text>
                <p>
                    These elements exude a sense of higher class or distinct flavor—both carrying
                    more profound and stronger value of purple. While we still wanted to provide a
                    feeling of trust, we didn’t want to give off a tone of royalty or power. We
                    wanted to be a bit more energetic and playful. I turned up the intensity level
                    ever so slightly to give a brighter purple hue that intended to resonate at the
                    collegiate level with both students and faculty alike.
                </p>
                <p>
                    That purple needed a counterpart that works together in harmony as our primary
                    colors. Since our new purple hue was on the brighter side of the color intensity
                    level, I wanted to pull back its partner to be a bit more subdued. This color
                    would balance the act and provide a slightly more distinctive flavor for the
                    right audience when appropriate.
                </p>
            </Text>
            <Text>
                <h3 className="text-2xl pb-4">Secondary colors</h3>
                <p>
                    After focussing on the primary colors, I selected the secondary colors that
                    would play well with their leading cast. This subset of colors will act as the
                    supporting cast and intend to strike the right balance depending on the use
                    case.
                </p>
            </Text>
            <Section>
                <Figure
                    slug={slug}
                    fileName="secondary-colors.png"
                    caption="Unizin brand guideline secondary colors"
                />
            </Section>
            <Text>
                <h2 className="text-3xl pb-4">Voice & Tone</h2>
                <p>
                    The organization I was working at occupies the higher-education space. We needed
                    to consider how our audience perceived our brand. However, this was a pretty
                    broad audience. The age range and professional expertise would range quite
                    vastly. The brand should speak to anyone at each end of age range and knowledge
                    and everyone in between. Knowing this meant we need to consider how we external
                    our internal mission externally.
                </p>
                <p>
                    Working with the leadership team, I led a process that would help define how
                    that messaging framework would become the voice and tone of our organization.
                </p>
            </Text>
            <Text>
                <h2 className="text-3xl pb-4">Visual accents</h2>
                <p>
                    Since data figures and statistics are at the core of our offerings, our accents
                    intend to carry that narrative forward. These assets would be a series of basic
                    shapes such as squares, triangles, circles, line variations, etc., that would
                    work either independently or collectively to add to the visual flair of our
                    brand and present a certain level of playfulness.
                </p>
            </Text>
            <Section>
                <Figure
                    slug={slug}
                    fileName="shapes.png"
                    caption="Visual accents basic shapes and structures"
                />
            </Section>
            <Text>
                <h2 className="text-3xl pb-4">A clear path forward</h2>
                <p>
                    This rebranding effort was an inspiring and rewarding project that was long
                    overdue. It was reasonably clear that you could see the diminishing values of
                    our identity occurring over time. Without a clear set of guidelines to help keep
                    your brand on track, alignment can become extremely difficult to retain. Now
                    that policies are in place, there is a more transparent set of expectations for
                    applying our brand strategy to our properties and assets.
                </p>
            </Text>
        </ProjectWrapper>
    );
}

export default UnizinBrandGuidelines;
