import styled from 'styled-components';
import theme from '../../theme';

function ProjectCategory(props) {
    const { kicker, text } = props;
    return (
        <Category>
            <Kicker>{kicker}</Kicker>
            {text}
        </Category>
    );
}

const Category = styled.li``;

const Kicker = styled.div`
    text-transform: uppercase;
    font-size: ${theme.spacing.plus2};
    font-weight: 800;
    letter-spacing: 1px;
    color: ${theme.colors.gray.light};
`;

export default ProjectCategory;
