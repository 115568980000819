import React, { useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import classnames from 'classnames';
import Section from './section';
import theme from '../theme';

function Tabs(props) {
    // const Tabs = ({ tabs, defaultIndex = 0 }) => {
    const { tabs, primaryColor, project } = props;
    const defaultIndex = 0;

    // const classes = classnames(`content`, {
    //     'full-width': isFullWidth,
    //     [`${className}`]: className,
    // });

    const onTabClick = (index) => {
        setActiveTabIndex(index);
    };

    const [activeTabIndex, setActiveTabIndex] = useState(defaultIndex);

    const tabContentVariant = {
        active: {
            opacity: 1,
        },
        inactive: {
            opacity: 0,
        },
    };

    // AnimateSharedLayout wrapper was removed.
    // need to use `layoutId` prop to animate components
    // https://www.framer.com/motion/guide-upgrade/##shared-layout-animations

    return (
        <StyledTabs isFullWidth>
                {/* change to image active tab selection (title,url,caption) */}
                <ul role="tablist">
                    {tabs.map((tab, index) => (
                        <li
                            key={index}
                            className={classnames('tab', { active: activeTabIndex === index })}
                            role="tab"
                            onClick={() => onTabClick(index)}
                        >
                            {tab.title}
                            {activeTabIndex === index && (
                                <motion.div
                                    layoutId="outline"
                                    className="active-outline"
                                    initial={false}
                                    animate={{ borderColor: primaryColor }}
                                />
                            )}
                        </li>
                    ))}
                </ul>
                {tabs.map((tab, index) => (
                    <motion.div
                        role="tabpanel"
                        id={`tab-${index}`}
                        key={`tab-${index}`}
                        className={classnames({ active: activeTabIndex === index })}
                        animate={activeTabIndex === index ? 'active' : 'inactive'}
                        variants={tabContentVariant}
                        initial="inactive"
                    >
                        <figure>
                            <img
                                src={
                                    require(`../assets/work/${project}/designs/${index + 1}.png`)
                                }
                                alt=""
                            />
                            <figcaption>{tab.caption}</figcaption>
                        </figure>
                    </motion.div>
                ))}
        </StyledTabs>
    );
}

const StyledTabs = styled(Section)`
    max-width: ${theme.breakpoints.desktop};
    margin: auto;

    ul[role='tablist'] {
        list-style: none;
        margin: 0 0 ${theme.spacing.plus3};
        padding: 0;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
        column-gap: ${theme.spacing.plus2};
        font-size: ${theme.font.sizes.minus1};
        font-weight: 600;

        li:hover {
            cursor: pointer;
        }
    }

    > div {
        display: none;

        figure {
            margin: 0;
            padding-bottom: ${theme.spacing.plus5};
        }

        &.active {
            display: block;
        }

        img {
            width: 100%;
        }
    }

    .active-outline {
        margin-top: ${theme.spacing.plus1};
        border: 1px solid ${(props) => props.primaryColor};
    }

    @media (max-width: ${theme.breakpoints.tablet}) {
        padding: 0;
    }
`;

export default Tabs;
