import { useNavigate } from "react-router";

const title = 'Senior Product Designer, Design Systems ';
const socialImages = {
    behance: './assets/social/behance.png',
};

const aboutPhotos = [
    'bike-rock.png',
    'crater-lake.png',
    'jack.png',
    'public-market.png',
    'roots.png',
    'sand.png',
    'rainier.png',
    'trail.png',
    'spheres.png',
    'trees.png',
];

const projects = [
    {
        slug: 'reordering',
        title: 'Reordering Digital Course Materials',
        excerpt: 'Expediting the ordering process',
        primaryColor: '#DC68FD',
        discipline: 'user experience',
        featured: true,
        categories: {
            category: 'UX & UI Design',
            roles: 'UX Lead, UX Design, UX Research, UI Design',
            platform: 'Figma',
            timeline: 'Jan 2020 - Jun 2021',
        },
    },
    {
        slug: 'student-success',
        title: 'Student Success',
        excerpt: 'Define the needs and problems for an emerging course materials experience',
        primaryColor: '#1497F0',
        discipline: 'user experience research',
        featured: true,
        categories: {
            category: 'UX Research & UX Design',
            roles: 'UX Lead, UX Design, UX Research, UI Design',
            platform: 'Sketch, Notion',
            timeline: 'Mar 2020 - May 2020',
        },
    },
    {
        slug: 'unizin-design-system',
        title: 'Unizin Design System',
        excerpt: 'Scaffolding a systematic process for our internal components',
        primaryColor: '#C720F7',
        discipline: 'user interface',
        featured: true,
        categories: {
            category: 'Design System',
            roles: 'Design Systems Lead, UX Design, UX Research, UI Design',
            platform: 'Sketch, Figma',
            timeline: 'Jan 2021 - Current',
        },
    },
    {
        slug: 'order-tool',
        title: 'Order Tool',
        excerpt: 'Building and supporting a digital content ordering experience',
        primaryColor: '#DF204A',
        discipline: 'user experience',
        featured: false,
        categories: {
            category: 'UX & UI Design',
            roles: 'UX Lead, UX Design, UX Research, UI Design',
            platform: 'Sketch, Desktop, Mobile',
            timeline: 'Jan 2020 - Dec 2020',
        },
    },
    {
        slug: 'unizin-brand-guidelines',
        title: 'Unizin Brand Guidelines',
        excerpt: "Updating and organization's brand identity",
        primaryColor: '#FEF0DB',
        featured: false,
        discipline: 'branding',
        categories: {
            category: 'Branding & UX Lead',
            roles: 'Design Director, Designer',
            platform: 'Adobe Creative Suite',
            timeline: 'Jan 2021 - July 2021',
        },
    },
    {
        slug: 'unizin-summit-2020',
        title: 'Unizin Summit 2020',
        excerpt: 'Marketing material and swag for our annual summit',
        primaryColor: '#3A295E',
        discipline: 'branding',
        featured: false,
        categories: {
            category: 'Branding & Design',
            roles: 'Branding & UX Lead, Design Director, Designer',
            platform: 'Sketch, Adobe Creative Suite',
            timeline: 'Jan 2020 - April 2020',
        },
    },
    {
        slug: 'unizin-logo-redesign',
        title: 'Unizin Logo Redesign',
        excerpt: 'Refreshing our logo identity',
        primaryColor: '#9C24F7',
        discipline: 'branding',
        featured: false,
        categories: {
            category: 'Logo Design',
            roles: 'Design Director, Designer',
            platform: 'Adoble Illustrator',
            timeline: 'Jan 2020 - Dec 2020',
        },
    },
    // {
    //     slug: 'latin-at',
    //     title: 'Latin@ Facebook Diversity',
    //     excerpt: 'Celebrating workplace diversity at Facebook',
    //     primaryColor: '#FFE86C',
    //     categories: {
    //         category: 'Logo Design',
    //         roles: 'Design Director, Designer',
    //         platform: 'Adoble Illustrator',
    //         timeline: 'Jan 2020 - Dec 2020',
    //     },
    // },
];

// use the following to determine the 'ideal' aspect ratio
// for project images
const aspectRatio = 0.6545454545;
function imageMinHeight(breakpoint) {
    return Math.floor(parseInt(breakpoint) * aspectRatio);
}


const withRouter = (Component) => {
	const Wrapper = (props) => {
		const history = useNavigate();
		return <Component history={history} {...props} />;
	};
	return Wrapper;
};

const utils = {
    title,
    projects,
    socialImages,
    aboutPhotos,
    imageMinHeight,
    withRouter,
};

export default utils;
