import { Link } from 'react-router-dom';
import styled from 'styled-components';
import theme from '../../theme';

function ProjectPagination(props) {
    const { projects, slug } = props;
    const projectIndex = projects.findIndex((item) => {
        return item.slug === slug;
    });

    const previousProject = projects[projectIndex === 0 ? projects.length - 1 : projectIndex - 1];
    const nextProject = projects[projectIndex === projects.length - 1 ? 0 : projectIndex + 1];

    return (
        <Pagination className="flex">
            <Link to={`/work/${previousProject.slug}`} className="flex-1 no-border">
                <div className="flex items-center">
                    <div className="flex-initial">
                        <img
                            src={
                                require(`../../assets/work/${previousProject.slug}/promo-teaser.png`)
                            }
                            alt={`${nextProject.title} promo`}
                        />
                    </div>
                    <div className="flex-1 pl-8">
                        <Kicker>Previous</Kicker>
                        <ProjectTitle>{previousProject.title}</ProjectTitle>
                        <Excerpt>{nextProject.excerpt}</Excerpt>
                    </div>
                </div>
            </Link>
            <Link to={`/work/${nextProject.slug}`} className="flex-1 no-border">
                <div className="flex items-center flex-row-reverse">
                    <div className="flex-initial">
                        <img
                            src={
                                require(`../../assets/work/${nextProject.slug}/promo-teaser.png`)
                            }
                            alt={`${nextProject.title} promo`}
                        />
                    </div>
                    <div className="flex-1 pl-8">
                        <Kicker>Next</Kicker>
                        <ProjectTitle>{nextProject.title}</ProjectTitle>
                        <Excerpt>{nextProject.excerpt}</Excerpt>
                    </div>
                </div>
            </Link>
        </Pagination>
    );
}

const ProjectTitle = styled.div`
    @media (max-width: ${theme.breakpoints.mobileLandscape}) {
        font-size: ${theme.font.sizes.plus1};
        line-height: ${theme.font.sizes.plus1};
    }
`;

const Pagination = styled.div`
    align-items: center;
    border-top: 1px solid ${theme.colors.gray.dark};

    > a.flex-1 {
        font-size: ${theme.font.sizes.plus2};
        line-height: ${theme.font.sizes.plus2};
        font-weight: bold;
        padding-right: ${theme.spacing.plus3};
        transition: all 0.25s;

        &:hover {
            background: ${theme.colors.gray.dark};
        }

        &:last-child {
            border-left: 1px solid ${theme.colors.gray.dark};
            padding-right: 0;
            padding-left: ${theme.spacing.plus3};

            .flex-1 {
                text-align: right;
                padding-right: ${theme.spacing.plus3};
            }
        }
    }

    img {
        max-width: 220px;
    }

    @media (max-width: ${theme.breakpoints.mobileLandscape}) {
        flex-direction: column-reverse;

        > a.flex-1 {
            min-width: 100vw;
            font-size: ${theme.font.sizes.plus2};
            line-height: ${theme.font.sizes.plus2};

            .flex-1 {
                padding: ${theme.spacing.plus1};
            }

            &:last-child {
                padding-left: 0;

                .flex-1 {
                    padding: ${theme.spacing.plus1};
                }
            }
        }

        img {
            max-width: 68px;
        }
    }
`;

const Kicker = styled.div`
    text-transform: uppercase;
    font-size: ${theme.font.sizes.minus2};
    line-height: ${theme.font.sizes.default};
    font-weight: normal;
    letter-spacing: 1px;

    @media (max-width: ${theme.breakpoints.mobileLandscape}) {
        font-size: ${theme.font.sizes.minus3};
        line-height: ${theme.font.sizes.defaul2};
    }
`;

const Excerpt = styled.div`
    font-weight: normal;
    font-size: ${theme.font.sizes.default};

    @media (max-width: ${theme.breakpoints.mobileLandscape}) {
        font-size: ${theme.font.sizes.minus1};
        line-height: ${theme.font.sizes.default};
    }
`;

export default ProjectPagination;
