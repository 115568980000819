import { Route, Routes, useParams } from 'react-router-dom';
import styled from 'styled-components';
import ScrollToTop from './components/scroll-to-top';
import Home from './components/home';
import Footer from './components/footer';
import NavigationMenu from './components/navigation-menu';
import OrderTool from './components/work/order-tool';
import UnizinDesignSystem from './components/work/unizin-design-system';
import UnizinSummit2020 from './components/work/unizin-summit-2020';
import UnizinLogoRedesign from './components/work/unizin-logo-redesign';
import UnizinBrandGuidelines from './components/work/unizin-brand-guidelines';
import LatinAt from './components/work/latin-at';
import Reordering from './components/work/reordering';
import StudentSuccess from './components/work/student-success';
import About from './components/about';
import Work from './components/work';
import theme from './theme';
import utils from './utils';
import './css/app.scss';
import useGoogleAnalytics from './useAnalyticsHook';

function App() {
    useGoogleAnalytics();
    return (
        <>
            <ScrollToTop />
            <NavigationMenu hideProjects />
            <Main>
                <Routes>
                    <Route path="/" exact element={<Home />} />
                    <Route path="/work/:slug" element={<RenderProject />} />
                    <Route path="/work" exact element={<Work />} />
                    <Route path="/about" exact element={<About />} />
                </Routes>
            </Main>
            <Footer />
        </>
    );
}

function RenderProject() {
    let { slug } = useParams();

    const { projects } = utils;
    const project = projects.find((item) => {
        return item.slug === slug;
    });

    switch (slug) {
        case 'order-tool':
            return <OrderTool project={project} />;
        case 'reordering':
            return <Reordering project={project} />;
        case 'student-success':
            return <StudentSuccess project={project} />;
        case 'latin-at':
            return <LatinAt project={project} />;
        case 'unizin-design-system':
            return <UnizinDesignSystem project={project} />;
        case 'unizin-summit-2020':
            return <UnizinSummit2020 project={project} />;
        case 'unizin-brand-guidelines':
            return <UnizinBrandGuidelines project={project} />;
        case 'unizin-logo-redesign':
            return <UnizinLogoRedesign project={project} />;
        default:
            return <Home />;
    }
}

const Main = styled.main`
    margin: auto;

    h1 {
        font-size: ${theme.spacing.plus5};
        line-height: ${theme.spacing.plus5};
    }
`;

export default App;
