import styled from 'styled-components';
import classnames from 'classnames';
import theme from '../theme';

function Content(props) {
    const { className, children, isFullWidth } = props;

    const classes = classnames(`content`, {
        'full-width': isFullWidth,
        [`${className}`]: className,
    });
    return <SyledContent className={classes}>{children}</SyledContent>;
}

const SyledContent = styled.div`
    max-width: ${theme.breakpoints.desktopHD};
    margin: auto;

    &.full-width {
        max-width: 100vw;
    }
`;

export default Content;
