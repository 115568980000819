import styled from 'styled-components';
import { Link } from 'react-router-dom';
import ProjectTeasers from './projects/project-teasers';
import theme from '../theme';

import utils from '../utils';

function Navigation(props) {
    const { hideProjects, hideProjectLinks } = props;
    const { projects } = utils;
    return (
        <StyledNavigation className="flex flex-col nav">
            <div className="flex-grow flex items-center">
                <ul className="nav-links">
                    <li>
                        <Link to="/" onClick={props.handleClick}>
                            Home
                        </Link>
                    </li>
                    <li>
                        <Link to="/work" onClick={props.handleClick}>
                            Portfolio
                        </Link>
                        {projects.length && !hideProjectLinks ? (
                            <ul>
                                {projects.map((project) => (
                                    <li key={project.title}>
                                        <Link
                                            to={`/work/${project.slug}`}
                                            onClick={props.handleClick}
                                        >
                                            {project.title}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        ) : null}
                    </li>
                    <li>
                        <Link to="/about" onClick={props.handleClick}>
                            About
                        </Link>
                    </li>
                </ul>
            </div>

            {!hideProjects ? (
                <div className="flex-none">
                    <ProjectTeasers isSmall handleClick={props.handleClick} isHorizontalScroll />
                </div>
            ) : null}
        </StyledNavigation>
    );
}

const StyledNavigation = styled.div`
    padding: 0 ${theme.spacing.plus5};
    height: 100vh;

    .nav-links {
        list-style: none;
        margin: 0;
        padding: 0;
        margin-right: ${theme.spacing.plus2};
    }

    .teasers {
        padding: ${theme.spacing.plus4} 0 0 0;

        h1 {
            margin: 0 0 ${theme.spacing.plus2} 0;
            padding-left: 0;
        }

        h2 {
            font-size: ${theme.font.sizes.plus1};
            padding-bottom: 0;
        }

        li {
            display: inline-block;
            margin: 0;
            padding: 0;
        }
    }
`;

export default Navigation;
