import ProjectWrapper from './project-wrapper';
import Text from '../text';

function StudentSuccess(props) {
    const {
        project: { slug },
    } = props;

    return (
        <ProjectWrapper slug={slug}>
            <Text>
                <p>
                    The academic experience in post-secondary education is undergoing a dramatic
                    transformation. A renewed emphasis on pedagogy, course design, engagement, and
                    personalization shapes how design teaching and learning is unfolding. The
                    attitude is systemic and holistic. Students, should be engaged by designed,
                    compelling learning experiences throughout their college career and beyond.
                    Their experience should be unified and aligned with their goals.
                </p>
            </Text>
            <Text>
                <h2 className="text-3xl pb-4">Problem to be solved</h2>
                <p>
                    This research project aimed to defining the needs and problems for an emerging
                    course materials experience that itself must support a modern, digital,
                    holistic, personalized academic experience.
                </p>
                <p>
                    This study also intended to inform the product team of a clear set of challenges
                    ahead of us for transitioning our reader application into this emerging academic
                    experience.
                </p>
                <h3 className="text-2xl pb-4">Goals</h3>
                <ul>
                    <li>Compare the landscape of online learning environments.</li>
                    <li>
                        Understand how the shifting paradigm in learning experiences is seen through
                        the lens of the student and instructor persona.
                    </li>
                    <li>
                        Get an understanding how what types and frequency of communications is a
                        desirable experience from the student perspective.
                    </li>
                </ul>
            </Text>
            <Text>
                <h2 className="text-3xl pb-4">Execution</h2>
                <p>
                    This research was conducted over three different research methods. The
                    sequential phases intend to help build a structure of what was imagined as
                    crucial to a new learning environment. Each stage being informed by the former
                    but not necessarily reliant on one another to exist.
                </p>
                <ol>
                    <li>Persona definitions</li>
                    <li>Competitive analysis</li>
                    <li>Semi-structured interviews</li>
                    <li>Moderated usability test</li>
                </ol>
            </Text>

            <Text>
                <h2 className="text-3xl pb-4">Persona definitions</h2>
                <p>
                    I model my design and development processes as they pertain to a series of
                    personas. Those personas include our primary user types. These examples attempt
                    to cover the general user type and have the nuances and specific attributes
                    paramount to understanding and developing the broader range of our user base.
                </p>
                <p>Below are the user personas that pertain to the reader application.</p>
                {'{Example user personas for the reader research project}'}
            </Text>

            {/* {image of personas} */}

            <Text>
                <h2 className="text-3xl pb-4">Competitive Analysis</h2>
                <p>
                    There were various aspects of three popular market platforms that were evaluated
                    for this portion of the study. This evaluation aimed to determine what factors
                    made each tool unique to the marketplace and what aspects of that uniqueness
                    might be valuable to understand and why.
                </p>
                <p>
                    This study also sought to find common patterns that learners are accustomed to
                    seeing when engaging with LX tools outside of their academic environment.
                </p>
                <p>
                    Most importantly, this study intended to see how students progress through their
                    learning objectives and how they could measure that progress along the way.
                </p>

                {/* Coursera, Udacity, Udemy */}

                <h3 className="text-2xl pb-4">Insights from competitive analysis</h3>
                <ol>
                    <li>
                        <b>Personalized Dashboard:</b> All three online educational platforms have a
                        personalized dashboard as their primary page after logging in. Each
                        dashboard contains an overview of the user's past and present engagement
                        with the course.
                    </li>
                    <li>
                        <b>Useful Recommendation:</b> Online educational platforms try to retain the
                        users by providing valuable recommendations of the courses they might be
                        interested in taking. They base these recommendations on the user's chosen
                        courses, areas of interest, engagement on the platform, and other data.
                    </li>
                    <li>
                        <b>Classroom View:</b> Once users are enrolled in a course, starting the
                        course directs them to a "classroom experience" view which includes videos,
                        lessons, progress indication, note-taking, Q&A, announcements, and
                        discussion forums.
                    </li>
                    <li>
                        <b>Progress Visualization:</b> Seeing progress is an essential aspect of
                        students' experiences with online courses. Progress typically indicates
                        where users started, what has been completed, and what's left to complete.
                        Some platforms also show the percent of course completion.
                    </li>
                    <li>
                        <b>Note Taking/Saving/Documenting:</b> Some online platforms allow saving
                        your notes with a snapshot and timestamp of the video. Other platforms have
                        a similar capability to take notes, but not with the video timestamp.
                    </li>
                    <li>
                        <b>Collaboration with Instructors and Students:</b> The majority of the
                        online educational platforms have the capabilities for students to interact
                        with each other and the instructors in the form of discussion forums, Q & A
                        sessions, etc.
                    </li>
                    <li>
                        <b>Rewards - Mentorship, Add to Resume, Certificates, Career Support:</b>{' '}
                        Features typically available for users enrolled in paid courses.
                    </li>
                </ol>
            </Text>
            <Text>
                <h2 className="text-3xl pb-4">Semi-structured interviews (Remote)</h2>
                <p>
                    The second portion of this study involved conducting semi-structured interviews
                    with five students from various campuses—the students ranging in diverse
                    undergraduate academic experiences, a variety of their field of studies.
                </p>
                <p>
                    The intent was to understand more about students' learning experiences and how
                    their institution has facilitated online education. The interviews were
                    conducted in two parts; each lasted approximately one hour in length. The first
                    part was to have a conversation with the students with the following goals:
                </p>
                <ul>
                    <li>
                        To understudents'udents study behaviors, motivations, habits, and best
                        practices.
                    </li>
                    <li>
                        To identify the course materials students use and the material's influence
                        on students.
                    </li>
                    <li>
                        To understand the role that notifications and feedback plays in student
                        behavior.
                    </li>
                    <li>To undetheirand students current online learning experience.</li>
                    <li>To learn about students' expectations for privacy</li>
                </ul>

                <h3 className="text-2xl pb-4">What was learned</h3>
                <p>
                    Participants have taken courses that both lay the foundational theoretical
                    knowledge and have practical applicability. Participants' typical day involves
                    listening to live or recorded lectures or combining both, doing assigned
                    readings, and posting discussions online. Some of them work independently
                    regularly, while others work in a group setting, collaborating online.
                </p>
                <ol>
                    <li>
                        Students rely more on digital tools vs. paper tools for short-term academic
                        planning.
                    </li>
                    <li>
                        How the course is structured plays a big role in the online learning
                        experience for students.
                    </li>
                    <li>
                        Ideal learning environment: Students in small classes prefer to learn in
                        person; students in large courses prefer online lectures.
                    </li>
                    <li>
                        Students associate success in a course with good grades and practical
                        applicability.
                    </li>
                    <li>
                        Learning management system (LMS) dashboard, calendars, and notifications
                        help students stay up to date with their coursework.
                    </li>
                    <li>
                        Students prefer digital textbooks over physical textbooks due to
                        affordability, portability, and customizability. However, some students
                        still struggle with eText readability and discoverability.
                        <ol>
                            <li>
                                The online class experience is not the same as in-person classes due
                                to several factors. There's a tradeoff between flexibility and
                                self-discipline.
                            </li>
                            <li>
                                Practical, hands-on classes are challenging to translate online.
                            </li>
                            <li>
                                Structured classes tend to do better than flexible courses online.
                            </li>
                            <li>
                                Lectures and collaborations are the most affected components of the
                                transition to online courses.
                            </li>
                        </ol>
                    </li>
                </ol>
            </Text>

            <Text>
                <h2 className="text-3xl pb-4">Moderated Usability Testing</h2>
                <p>
                    The third portion of this study involved gathering an understanding of how
                    students respond to user interface structures that support our earlier findings
                    through a <b>moderated usability evaluation</b>.
                </p>
                <p>
                    To evaluate their response, I created a <b>wireframe prototype</b> and asked
                    these subjects to accomplish a series of tasks using the prototype. Otherwise
                    known as a <b>task-based evalaution</b>. After the users engaged with the
                    prototype, the focus turned to conversing about specific portions of the
                    interactions and how they felt about their successes and struggles.
                </p>
                <p>
                    After that, a few followup questions were asked about their perceptions of the
                    interactions. Here are the sythentized outcomes of moderated usability testing
                    portion of the study.
                </p>
                <ul>
                    <li>
                        <p>
                            <b>
                                Students are reluctant to share their status with their instructor.
                            </b>
                            <br />
                            There were assumptions made that their instructor likely already had
                            access to the information, but students were less inclined to notify
                            their instructor about their status. When allowed to create customizable
                            and highly-tuned messaging methods of self-tracking, the participants
                            were receptive to the idea, or concept, of personal messaging about
                            status. Still, they were reluctant to share that same information with
                            their instructors.
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>Students prefer structured courses.</b>
                            <br />
                            Structured courses give students a greater understanding of expected
                            learning outcomes vs. non-structured courses. Being given an outline of
                            how their learning should unfold through a series of units (or modules)
                            gives the students a framework or structure on how to consume their
                            resources for better outcomes.
                        </p>
                        <p>
                            In one case, a participant called out or expressed a distaste for locked
                            modules based on future dates. The participant thought this method
                            discouraged ambition or the desire to work ahead of current
                            expectations. This participant understood the sequential learning
                            process and how a previous module would or could limit the understanding
                            of future modules and comprehend how sequential learning could apply to
                            the locked structure of module access. Still, the subject did not prefer
                            to be disallowed access to learning solely on a date-based format.
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>
                                Students prefer push notifications and text messaging over email
                                messaging.
                            </b>
                            <br />
                            Most participants used more inclined notifications methods to keep up to
                            date or on track with their peers, keep track of their current status
                            (grades), receive course updates, etc. However, most participants were
                            displeased with the number of notifications they received that had
                            little to no impact on their overall grading. As a result, they
                            expressed interest in tuning the volume of methods in which they receive
                            their messages.
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>A sense of accountability rises with strong relationships.</b>
                            <br />
                            When students feel that their instructor is more involved in their lives
                            or relationship as it pertains to learning, they feel more accountable
                            for their actions or results. One participant mentioned
                        </p>
                    </li>
                </ul>
            </Text>

            <Text>
                <h2 className="text-3xl pb-4">Key takeaways from this project</h2>
                <p>
                    The Student Success research project enabled us to get a much better
                    understanding of how the student and faculty personas expect out of a renewed
                    modeling of their learning experiences.
                </p>
                <p>
                    After all the study results were examined and synthesized, these were four key
                    takeaways for the team to focus on the portion of the application that mattered
                    most to targeted personas.
                </p>
                <h3 className="text-2xl pb-4">
                    1. Students rely heavily on LMS Calendar to plan their schoolwork.
                </h3>
                <p>
                    While some subjects took part in their organizational processes, the majority
                    used the LMS Calendar exclusively to navigate their responsibilities.
                </p>
                <h3 className="text-2xl pb-4">
                    2. Structured courses are highly preferred over non-structured courses.
                </h3>
                <p>
                    Students have a greater understanding of what's learning outcomes are expected
                    of them due to structured courses.
                </p>
                <h3 className="text-2xl pb-4">
                    3. Students lean on their LMS to understand their current standing.
                </h3>
                <p>The LMS Dashboard or LMS Course view was used to receive their current grade.</p>
                <h3 className="text-2xl pb-4">
                    4. Students prefer push notifications and text messaging over email messaging.
                </h3>
                <p>
                    Collaboration and communication happening at the student level are most commonly
                    consumed by more inline or timely messaging methods such as push notification
                    (on native mobile apps) and text messaging.
                </p>
            </Text>
        </ProjectWrapper>
    );
}

export default StudentSuccess;
